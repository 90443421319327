<template>
    <div class="container">
        <div class="overall">
            <div class="chart_title">总体市场走势</div>
            <el-row :gutter="0">
                <el-col :xs="24" :sm="24" :md="8" :lg="14" style="position: relative">
                    <div class="btnArea ml80">
                        <CTimeButtons :default="start_date_chart1And2"
                            @CTimeButtons_select_val="fnCTimeButtons_select_val_chart1And2" />
                    </div>
                    <div v-loading="loading1" element-loading-text="数据量巨大，正在计算中..."
                        element-loading-spinner="el-icon-loading" class="chart_content CHARTS_BGC chart1">
                        <div class="chart1_content_top">
                            <div class="chart1_content_top_tit">REITS及对标指数</div>
                            <div id="chartColumn1" ref="chartColumn1" style="width: 100%; height: 480px"></div>
                        </div>
                        <div class="chart1_content_bottom">
                            <div class="deadline_style">数据截止日期：{{ sReitsBenIndexDDL }}</div>
                        </div>
                    </div>
                    <!--放大按钮-->
                    <span @click="fnOpenDialog1" class="iconfont icon-fangda icon-fangda1"></span>
                    <!--下载按钮-->
                    <div class="downloadBtns">
                        <CButtonsDownload :dButtonsDownloads="dButtonsDownloads" @fnButtondownload="fnButtonDL" />
                        <span style="display: none">
                            <download-excel id="downloadExcelChart1" :data="dataCart1" :fields="jsonCart1"
                                header="总体市场走势" name="总体市场走势.xls">
                            </download-excel>
                        </span>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="16" :lg="10">
                    <div class="btnArea p10">
                        <CCascader2 @CCascader_select_val="fnCCascader_select_val_one" :default="select_val_benchmark"
                            ref="CCascader_chart1" :multiple="true" :isDelete="false" takeOut="takeOut2"
                            :collapsetags="true" :options="options_benchmark2">选择benchmark</CCascader2>
                    </div>
                    <div v-loading="loading1" element-loading-text="数据量巨大，正在计算中..."
                        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 1)"
                        class="chart_content CHARTS_BGC chart2">
                        <span @click="fnOpenDialog2" class="iconfont icon-fangda icon-fangda2"></span>

                        <div class="canvasArea2" id="chartColumn2" ref="chartColumn2"
                            style="width: 100%; height: 500px">
                        </div>
                    </div>
                    <!--放大按钮-->
                    <!-- <span @click="fnOpenDialog2" class="iconfont icon-fangda icon-fangda2"></span> -->

                </el-col>
            </el-row>
        </div>

        <div class="overall2">
            <div class="chart_title">分版块走势</div>
            <div class="btnArea btnArea2">
                <div class="chooseLev">
                    <CSelect @CSelect_select_val="fnCSelect_chart3And4And5" :multiple="false" :mini="true"
                        :clearable="false" :default="select_val_level" :options="options_level">选择层级</CSelect>
                </div>

                <div class="CTimeButtonStyle" style="margin-left: 10px;">
                    <CTimeButtons :default="start_date_chart3And4And5"
                        @CTimeButtons_select_val="fnCTimeButtons_select_val_chart3And4And5" />
                </div>
            </div>
            <el-row :gutter="0">
                <el-col :xs="24" :sm="24" :md="8" :lg="16" style="position: relative">
                    <div v-loading="loading3" element-loading-text="数据量巨大，正在计算中..."
                        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                        class="chart_content CHARTS_BGC">
                        <div class="chart1_content_top">
                            <div class="chart1_content_top_tit">REITS及对标指数</div>
                            <div class="canvasArea" id="chartColumn3" ref="chartColumn3"
                                style="width: 100%; height: 480px">
                            </div>
                        </div>
                        <div class="chart1_content_bottom">
                            <div class="deadline_style" style="margin-bottom: 10px">
                                数据截止日期：{{ sOnlyReitsIndexDDL }}
                            </div>
                        </div>
                    </div>
                    <!--放大按钮-->
                    <span @click="fnOpenDialog3" class="iconfont icon-fangda icon-fangda3"></span>
                    <!--下载按钮-->
                    <div class="downloadBtns" style="top:-55px;">
                        <CButtonsDownload :dButtonsDownloads="dButtonsDownloads" @fnButtondownload="fnButtonDL2" />
                        <span style="display: none">
                            <download-excel id="downloadExcelChart2" :data="dataCart2" :fields="jsonCart2"
                                header="分版块走势" name="分版块走势.xls">
                            </download-excel>
                        </span>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="16" :lg="8" class="overall2Right">
                    <div v-loading="loading3" element-loading-text="数据量巨大，正在计算中..."
                        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                        class="chart_content CHARTS_BGC chart4">
                        <div class="canvasArea" ref="chartColumn4" style="width: 100%; height: 100%"></div>
                    </div>

                    <div v-loading="loading3" element-loading-text="数据量巨大，正在计算中..."
                        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)"
                        class="chart_content CHARTS_BGC chart5">
                        <div class="chart5_tit">{{ chartColumn5_title }}板块走势热力图</div>
                        <div class="canvasArea" ref="chartColumn5" style="width: 100%; height: 100%"></div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="overall2 HEIGHTAUTO">
            <div class="chart_title chart3_title">个股走势及排名</div>
            <div class="btnArea">
                <CTimeButtons :default="start_date_chart6"
                    @CTimeButtons_select_val="fnCTimeButtons_select_val_chart6" />
            </div>
            <div v-loading="loading2" element-loading-text="数据量巨大，正在计算中..." element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 1)" class="echarts_AREA">
                <div class="canvasArea3" v-for="(node, index) in dCalSingleReitIndex_Ys" :key="index" :id="node.name"
                    style="height: 300px"></div>
            </div>
        </div>

        <!--折线图-->
        <CDialog ref="CDialog" :DIALOGHEIGHT="height_dialog" :dialogVisible="dialogVisibleChart1">
            <el-dialog title="葱花投研" :visible.sync="dialogVisibleChart1" :width="width_dialog" :top="top_dialog">
                <div>
                    <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown1">
                        <span class="el-dropdown-link">
                            {{ popUpWindowDatas[popUpWindowIndex]
                            }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                                {{ node }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>

                <div v-loading="loading1" element-loading-text="数据量巨大，正在计算中..."
                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 1)"
                    class="chart_content CHARTS_BGC chart2 bn" :style="{ height: height_chart }">
                    <div class="canvasArea2" ref="chartColumn1_dialog" style="width: 100%; height: 100%"></div>
                </div>
            </el-dialog>
        </CDialog>
        <!--方图-->
        <CDialog ref="CDialog" :DIALOGHEIGHT="height_dialog" :dialogVisible="dialogVisible">
            <el-dialog title="葱花投研" :visible.sync="dialogVisible" :width="width_dialog" :top="top_dialog">
                <div>
                    <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown2">
                        <span class="el-dropdown-link">
                            {{ popUpWindowDatas[popUpWindowIndex]
                            }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                                {{ node }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>

                <div v-loading="loading1" element-loading-text="数据量巨大，正在计算中..."
                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 1)"
                    class="chart_content CHARTS_BGC chart2 bn" :style="{ height: height_chart }">
                    <div class="canvasArea2" ref="chartColumn2_dialog" style="width: 100%; height: 100%"></div>
                </div>
            </el-dialog>
        </CDialog>
        <!--折线图-->
        <CDialog ref="CDialog" :DIALOGHEIGHT="height_dialog" :dialogVisible="dialogVisibleChart3">
            <el-dialog title="葱花投研" :visible.sync="dialogVisibleChart3" :width="width_dialog" :top="top_dialog">
                <div>
                    <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown3">
                        <span class="el-dropdown-link">
                            {{ popUpWindowDatas[popUpWindowIndex]
                            }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                                {{ node }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>

                <div v-loading="loading1" element-loading-text="数据量巨大，正在计算中..."
                    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 1)"
                    class="chart_content CHARTS_BGC chart2 bn" :style="{ height: height_chart }">
                    <div class="canvasArea2" ref="chartColumn3_dialog" style="width: 100%; height: 100%"></div>
                </div>
            </el-dialog>
        </CDialog>

    </div>
</template>


<script>
import RiskRelated from '@/components/MarketDynamics/RiskRelated';
import Mobility from '@/components/MarketDynamics/Mobility';
import FinancialData from '@/components/MarketDynamics/FinancialData';
import BenefitAnalysis from '@/components/MarketDynamics/BenefitAnalysis';
import CButtonsDownload from '@/components/Basic/CButtons_download';
import Test from '@/components/MarketDynamics/test';
import CTimeButtons from '@/components/Basic/CTimeButtons';
import CSelect from '@/components/Basic/CSelect';
import CSelectBench from '@/components/Basic/CSelectBench';
import CCascader2 from '@/components/Basic/CCascader2';
import CDialog from '@/components/Basic/CDialog';
import html2canvas from 'html2canvas';
import { fnGetMuchDecimals } from '@/utils/util';
import { fnDownloadBgc, fnComputeTime } from '@/utils/util';

export default {
    components: {
        RiskRelated,
        Mobility,
        FinancialData,
        BenefitAnalysis,
        Test,
        CTimeButtons,
        CSelect,
        CDialog,
        CSelectBench,
        CCascader2,
        CButtonsDownload,
    },
    data() {
        return {
            is_reload: false,

            dCalSingleReitIndex_Ys: [],
            sReitsBenIndexDDL: '',
            // 方图图弹窗
            dialogVisible: false,
            height_dialog: '73vh',
            height_chart: '72vh',
            width_dialog: '80%',
            popUpWindowDatas: this.$store.state.popUpWindowDatas,
            popUpWindowIndex: 0,
            top_dialog: '5vh',
            // 折线图弹窗
            dialogVisibleChart1: false,
            dialogVisibleChart3: false,

            category_cascader_options: this.$store.state.category_cascader_options,

            category_cascader_options2: [
                {
                    value: '产权类',
                    label: '产权类',
                    children: [
                        {
                            value: '物流仓储',
                            label: '物流仓储',
                        },
                        {
                            value: '产业园',
                            label: '产业园',
                        },
                        {
                            value: '保障性住房',
                            label: '保障性住房',
                        },
                        {
                            value: '消费',
                            label: '消费',
                        },
                    ],
                },
                {
                    value: '经营权类',
                    label: '经营权类',
                    children: [
                        {
                            value: '市政生态',
                            label: '市政生态',
                        },
                        {
                            value: '能源',
                            label: '能源',
                        },
                        {
                            value: '收费公路',
                            label: '收费公路',
                        },
                    ],
                },
                {
                    value: '公募REITs',
                    label: '公募REITs',
                },
            ],


            dButtonsDownloads: [
                {
                    id: '241017',
                    name: '下载图片',
                },
                {
                    id: '241017',
                    name: '下载表格',
                },
            ],
            chartColumn5: null,
            chartColumn5_title: '',

            // chart1和2
            start_date_chart1And2: 'YTD',
            options_benchmark: JSON.parse(localStorage.getItem('BENCHMARKNAME2')),
            options_benchmark2: null,
            // select_val_benchmark: ["中证红利", "沪深300", "CN10YR"],
            select_val_benchmark: [
                ['equity', '中证红利'],
                ['equity', '沪深300'],
                ['bond', 'CN10YR'],
            ],

            // chart3和4和5
            start_date_chart3And4And5: 'YTD',
            options_level: [
                {
                    label: '一级',
                    value: 'L1',
                },
                {
                    label: '二级',
                    value: 'L2',
                },
                {
                    label: '全部',
                    value: 'all',
                },
            ],
            select_val_level: 'L1',

            // chart6
            start_date_chart6: 'YTD',

            TABPANEHEIGHT: 0,
            ROOTFONTFIZE: 0,

            sOnlyReitsIndexDDL: '',

            // 下载表格
            dataCart1: [],
            jsonCart1: {},

            // 下载表格
            dataCart2: [],
            jsonCart2: {},

            // 遮罩层
            loading1: false,
            loading2: false,
            loading3: false,

            sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,
        };
    },
    created() {
        this.$nextTick(() => {
            this.fnGetWidth();
            this.fnInitBenchMark();
            window.addEventListener('resize', this.funResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.funResize);
        this.chartColumn1 && this.chartColumn1.dispose();
        this.chartColumn2 && this.chartColumn2.dispose();
        this.chartColumn3 && this.chartColumn3.dispose();
        this.chartColumn4 && this.chartColumn4.dispose();
        this.chartColumn5 && this.chartColumn5.dispose();
    },
    methods: {
        reload() {
            this.is_reload = true;
            this.fnGetReitsBenIndex();
            this.fnGetReitsAndBenCorr();
            this.fnGetOnlyReitsIndex();
            this.fnGetCapsizeReturn();
            this.fnGetCalSingleReitIndex();
        },

        // 总体市场走势 下载
        FnDownload() {
            let h = this.$refs.chartColumn1.scrollHeight;
            let w = this.$refs.chartColumn1.scrollWidth;
            // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
            html2canvas(this.$refs.chartColumn1, {
                height: h + 20,
                width: w,
                backgroundColor: fnDownloadBgc(this.sCurrentTheme),
                useCORS: true,
                scale: 1,
            }).then((canvas) => {
                let url = canvas.toDataURL('image/png');
                var a = document.createElement('a'); // 生成一个a元素
                var event = new MouseEvent('click'); // 创建一个单击事件
                a.download = '总体市场走势'; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            });
        },
        // 分版块走势 下载
        FnDownload2() {
            let h = this.$refs.chartColumn3.scrollHeight;
            let w = this.$refs.chartColumn3.scrollWidth;
            // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
            html2canvas(this.$refs.chartColumn3, {
                height: h + 20,
                width: w,
                backgroundColor: fnDownloadBgc(this.sCurrentTheme),
                useCORS: true,
                scale: 1,
            }).then((canvas) => {
                let url = canvas.toDataURL('image/png');
                var a = document.createElement('a'); // 生成一个a元素
                var event = new MouseEvent('click'); // 创建一个单击事件
                a.download = '分版块走势'; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            });
        },


        // 总体市场走势 下载 start
        fnButtonDL(val) {
            if (val == '下载图片') {
                this.FnDownload();
            } else {
                document.getElementById('downloadExcelChart1').click();
            }
        },
        // 分版块走势 下载
        fnButtonDL2(val) {
            if (val == '下载图片') {
                this.FnDownload2();
            } else {
                document.getElementById('downloadExcelChart2').click();
            }
        },
        // ------------------------放大指数--------------------------------------
        // 放大图表1
        fnOpenDialog1() {
            this.dialogVisibleChart1 = true;
            this.$nextTick(() => {
                this.initChart1('宽高一');
            });
        },
        fnOpenDialog2() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.initChart2('宽高一');
            });
        },
        fnOpenDialog3() {
            this.dialogVisibleChart3 = true;
            this.$nextTick(() => {
                this.initChart3('宽高一');
            });
        },

        funResize() {
            // this.fnGetWidth();
            this.$nextTick(() => {
                this.chartColumn1 && this.chartColumn1.resize();
                this.chartColumn2 && this.chartColumn2.resize();
                this.chartColumn4 && this.chartColumn4.resize();
                this.chartColumn5 && this.chartColumn5.resize();
                this.chartColumn3 && this.chartColumn3.resize();
            });
        },
        // 初始化benchmark
        fnInitBenchMark() {
            let arr1 = JSON.parse(localStorage.getItem('BENCHMARKNAME2'));
            let arr2 = JSON.parse(localStorage.getItem('BENCHMARKNAMEBENTYPE'));

            let arr3 = arr2.map((node) => {
                return node.benType;
            });

            arr3 = [...new Set(arr3)];

            localStorage.setItem('BENTYPE', JSON.stringify(arr3));

            let arr4 = arr3.map((node) => {
                let obj = {
                    value: node,
                    label: node,
                    children: [],
                };

                return obj;
            });

            arr1.forEach((node) => {
                let obj = arr2.find((item) => item.benName == node.value);
                if (obj) {
                    let bentype = obj.benType;
                    arr4.forEach((ele) => {
                        if (ele.value == bentype) {
                            ele.children.push(node);
                        }
                    });
                }
            });

            this.options_benchmark2 = arr4;
            this.fnNot();
        },
        // 禁止选择
        fnNot() {
            let value = null;
            if (typeof this.select_val_benchmark[0] == 'string') {
                value = this.select_val_benchmark;
            } else {
                value = this.select_val_benchmark.map((node) => {
                    return node[1];
                });
            }

            // 3种情况
            // 1. 等于3
            if (this.select_val_benchmark.length == 3) {
                this.options_benchmark2 = this.options_benchmark2.map((node) => {
                    node.children.forEach((ele) => {
                        let obj = value.find((item) => {
                            return item == ele.value;
                        });
                        if (!obj) {
                            ele['disabled'] = true;
                        } else {
                            ele['disabled'] = false;
                        }
                    });
                    return node;
                });
            }
            // 2. 大于1小于3
            if (this.select_val_benchmark.length < 3 && this.select_val_benchmark.length > 1) {
                this.options_benchmark2 = this.options_benchmark2.map((node) => {
                    node.children.forEach((ele) => {
                        ele['disabled'] = false;
                    });
                    return node;
                });
            }
            // 3. 等于1
            if (this.select_val_benchmark.length == 1) {
                this.options_benchmark2 = this.options_benchmark2.map((node) => {
                    node.children.forEach((ele) => {
                        let obj = value.find((item) => {
                            return item == ele.value;
                        });
                        if (obj) {
                            ele['disabled'] = true;
                        } else {
                            ele['disabled'] = false;
                        }
                    });
                    return node;
                });
            }
        },

        // 获取当前屏幕宽度
        fnGetWidth() {
            var element = document.getElementById('rootchart');
            let ROOTFONTFIZE = element.offsetWidth;
            ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
            ROOTFONTFIZE = ROOTFONTFIZE < 1400 ? ROOTFONTFIZE - 200 : ROOTFONTFIZE;

            this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

            window.sessionStorage.setItem('ROOTFONTFIZE', this.ROOTFONTFIZE);

            let OFFSETHEIGHT = element.offsetHeight;
            this.TABPANEHEIGHT = OFFSETHEIGHT - 135;

            var chartColumn1 = document.getElementById('chartColumn1');
            chartColumn1.style.height = this.ROOTFONTFIZE * (480 / 144) + 'px';

            var chartColumn2 = document.getElementById('chartColumn2');
            chartColumn2.style.height = this.ROOTFONTFIZE * (500 / 144) + 'px';

            var chartColumn3 = document.getElementById('chartColumn3');
            chartColumn3.style.height = this.ROOTFONTFIZE * (388 / 144) + 'px';

            return;
        },

        // chart1和2
        fnCTimeButtons_select_val_chart1And2(val) {
            this.start_date_chart1And2 = val;
            this.fnGetReitsBenIndex();
            this.fnGetReitsAndBenCorr();
        },
        fnCSelect_chart1And2(val) {
            if (!val[0]) {
                return this.$message.error('值不能为空');
            } else {
                this.select_val_benchmark = val;
                this.$refs.chooseBenchmark.value = this.select_val_benchmark;

                this.fnGetReitsBenIndex();
                this.fnGetReitsAndBenCorr();
            }
        },

        fnCCascader_select_val_one(val) {
            let arr = JSON.parse(localStorage.getItem('BENTYPE'));
            let data = val.filter((node) => {
                return !arr.includes(node);
            });
            this.select_val_benchmark = data;
            this.fnNot();

            this.fnGetReitsBenIndex(true);
            this.fnGetReitsAndBenCorr(true);
        },

        async fnGetReitsBenIndex(val) {
            this.loading1 = true;
            let ben_select = null;
            if (typeof this.select_val_benchmark[0] !== 'string') {
                // 修改一下数组 start
                ben_select = this.select_val_benchmark.map((node) => {
                    return node[1];
                });
                // 修改一下数组 end
            } else {
                ben_select = this.select_val_benchmark;
            }

            let str = ben_select.join('&ben_select=');

            let start_date = this.start_date_chart1And2;
            start_date = start_date == 'MAX' ? 'max' : start_date;

            const { data } = await this.$https.get(
                '/api/get_reits_ben_index?ben_select=' + str + '&start_date=' + start_date
            );
            if (data.code !== 200) return this.$message.error(data.msg);

            this.dReitsBenIndex = data.data;
            this.dataCart1 = this.dReitsBenIndex;
            this.jsonCart1 = {
                日期: 'tr_date',
                公募REITs: '公募REITs',
                中证红利: '中证红利',
                沪深300: '沪深300',
                CN10YR: 'CN10YR',
            };
            this.dReitsBenIndex_X = [];
            this.dReitsBenIndex_GM = [];
            this.dReitsBenIndex_HS = [];
            this.dReitsBenIndex_GY = [];
            this.dReitsBenIndex_CN = [];

            let dataTmp = data.data;

            for (let index = 0; index < dataTmp.length; index++) {
                this.dReitsBenIndex_X.push(dataTmp[index].tr_date);
                this.dReitsBenIndex_GM.push(dataTmp[index].公募REITs);
                this.dReitsBenIndex_HS.push(dataTmp[index].沪深300);
                this.dReitsBenIndex_GY.push(dataTmp[index].公用指数);
                this.dReitsBenIndex_CN.push(dataTmp[index].CN10YR);
            }

            this.sReitsBenIndexDDL = this.dReitsBenIndex_X[this.dReitsBenIndex_X.length - 1];

            let arr = this.sReitsBenIndexDDL.split('-');
            this.sReitsBenIndexDDL = arr[0] + '年' + arr[1] + '月' + arr[2] + '日';

            this.chart1Tmp = dataTmp;
            this.$nextTick(() => {
                this.initChart1();
            });
        },

        async fnGetStackingReturnData() {
            let val = this.chart3_data;
            this.loading3 = true;
            let r_type_ = Object.keys(val[0]);
            // let r_type_ = ['产权类', '经营权类', '公募REITs'];
            let annualize = '1';
            let cal_function = 'type';
            let str = r_type_.join('&r_type_=');
            // let r_type_ = "公募REITs";

            let freq_type = this.start_date_chart3And4And5;
            freq_type = freq_type == 'MAX' ? 'max' : freq_type;

            let codes = '';

            const { data } = await this.$https.get(
                '/api/stacking_return_data?annualize=' +
                annualize +
                '&cal_function=' +
                cal_function +
                '&codes=' +
                codes +
                '&r_type_=' +
                str +
                '&freq_type=' +
                freq_type
            );

            if (data.code !== 200) return this.$message.error(data.msg);

            this.dStackingReturnData = data.data;
            this.dStackingReturnData_X = [];
            this.dStackingReturnData_JG = [];
            this.dStackingReturnData_FH = [];
            this.dStackingReturnData_ZT = [];

            let dataTmp = data.data;

            for (let index = 0; index < dataTmp.length; index++) {
                this.dStackingReturnData_X.push(dataTmp[index].reit_code);
                this.dStackingReturnData_JG.push(dataTmp[index].价格回报);
                this.dStackingReturnData_FH.push(dataTmp[index].分红回报);
                this.dStackingReturnData_ZT.push(dataTmp[index].总体回报);
            }

            this.dStackingReturnData_JG = this.dStackingReturnData_JG.map((node) => {
                let val = node * 100;
                return val.toFixed(1);
            });

            this.dStackingReturnData_FH = this.dStackingReturnData_FH.map((node) => {
                let val = node * 100;
                return val.toFixed(1);
            });

            this.dStackingReturnData_ZT = this.dStackingReturnData_ZT.map((node) => {
                let val = node * 100;
                return val.toFixed(1);
            });
            this.$nextTick(() => {
                this.initChart4();
            });
        },

        initChart4() {
            this.chartColumn4 = this.$echarts.init(this.$refs.chartColumn4);
            this.chartColumn4.clear();

            let dStackingReturnData_X = this.dStackingReturnData_X;
            // dStackingReturnData_X = dStackingReturnData_X.slice(0, 3);
            let dStackingReturnData_JG = this.dStackingReturnData_JG;
            let dStackingReturnData_FH = this.dStackingReturnData_FH;
            let dStackingReturnData_ZT = this.dStackingReturnData_ZT;

            let sum1 = 0;
            let sum2 = 0;
            let sum3 = 0;
            dStackingReturnData_X.forEach((node, idx) => {
                if (idx == 0) {
                    sum1 += Number(dStackingReturnData_JG[idx]);
                    sum1 += Number(dStackingReturnData_FH[idx]);
                }

                if (idx == 1) {
                    sum2 += Number(dStackingReturnData_JG[idx]);
                    sum2 += Number(dStackingReturnData_FH[idx]);
                }

                if (idx == 2) {
                    sum3 += Number(dStackingReturnData_JG[idx]);
                    sum3 += Number(dStackingReturnData_FH[idx]);
                }
            });

            let sum = [sum1, sum2, sum3];

            let min = Math.min(...sum);
            let max = Math.max(...sum);

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let option4 = {
                legend: {
                    show: true,
                    orient: 'horizontal',
                    top: this.ROOTFONTFIZE * (15 / 144),
                    left: this.ROOTFONTFIZE * (20 / 144),
                    align: 'left',
                    icon: 'roundRect',
                    itemGap: this.ROOTFONTFIZE * (20 / 144),
                    itemHeight: this.ROOTFONTFIZE * (12 / 144),
                    itemWidth: this.ROOTFONTFIZE * (12 / 144),
                    padding: [0, 0, 0, 0],
                    lineStyle: {
                        color: 'inherit',
                    },
                    textStyle: {
                        color: OPTIONDATA.legend.textStyle.color,
                        fontSize: this.ROOTFONTFIZE * (14 / 144),
                    },
                    data: [
                        {
                            name: '价格回报',
                            icon: 'roundrect',
                        },
                        {
                            name: '分红回报',
                            icon: 'roundrect',
                        },
                        {
                            name: '总体回报',
                            icon: 'circle',
                        },
                    ],
                },

                grid: {
                    left: this.ROOTFONTFIZE * (10 / 144),
                    bottom: this.ROOTFONTFIZE * (10 / 144),
                    right: this.ROOTFONTFIZE * (10 / 144),
                    top: this.ROOTFONTFIZE * (50 / 144),
                    width: '95%',
                    containLabel: true,
                },
                tooltip: {
                    // 工具提示
                    // trigger:'item',
                    trigger: 'axis', // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
                    triggerOn: 'mousemove|click', // 触发时机, click代表点击, mouseOver代表鼠标移过
                    formatter: function (args) {
                        let str = '';
                        str += args[0].axisValueLabel;
                        str += '<br>';
                        args.forEach((node) => {
                            let val = Number(node.value);
                            let num = fnGetMuchDecimals(val);
                            val = val.toFixed(num);
                            str += `${node.marker}${node.seriesName}：${val}%`;
                            str += '<br>';

                            return str;
                        });
                        return str;

                        // let str = "";
                        // str += `YTD回报率：`;
                        // str += "<br>";
                        // str += `${args[0].marker}${args[0].name}：${args[0].data}%`;

                        // return str;
                    },
                },
                xAxis: {
                    type: 'category',
                    data: dStackingReturnData_X,
                    axisLine: {
                        show: false,
                        onZero: true, //轴线是否在0刻度轴上
                    },

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: this.ROOTFONTFIZE * (14 / 144),
                        show: true,
                        showMaxLabel: true,
                        margin: 20,
                        color: OPTIONDATA.xAxis.axisLabel.color,

                        // formatter: function (value) {
                        //   var str = "";
                        //   var num = 2; //每行显示字数
                        //   var valLength = value.length; //该项x轴字数
                        //   var rowNum = Math.ceil(valLength / num); // 行数

                        //   if (rowNum > 1) {
                        //     for (var i = 0; i < rowNum; i++) {
                        //       var temp = "";
                        //       var start = i * num
                        //       var end = start + num;

                        //       temp = value.substring(start, end) + "\n";
                        //       str += temp;
                        //     }
                        //     return str;
                        //   } else {
                        //     return value;
                        //   }
                        // },
                    },
                },
                yAxis: {
                    // scale: true,
                    type: 'value',
                    // min: -20,
                    // max: 20,
                    interval: 5,
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                        //x轴刻度相关设置
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        fontSize: this.ROOTFONTFIZE * (12 / 144),
                        show: true,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                        // formatter: "{value}%", //y轴百分比
                        formatter: function (val) {
                            return val.toFixed(1) + '%';
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.yAxis.splitLine.lineStyle.color,
                        },
                    },
                },
                series: [
                    {
                        name: '价格回报',
                        type: 'bar',
                        stack: 'all',
                        data: dStackingReturnData_JG,
                        // barWidth: "60px",
                        itemStyle: {
                            color: '#5acac6',
                        },
                        label: {
                            show: true,
                            position: 'insideBottom',
                            fontSize: this.ROOTFONTFIZE * (12 / 144),
                            color: '#fff',
                            formatter: function (value) {
                                let val = value.value;
                                let num = fnGetMuchDecimals(val);
                                val = Number(val).toFixed(num);
                                return val + '%';
                            },
                        },
                        markLine: {
                            symbol: ['none', 'none'], // none为标线两端的样式为无，可更改
                            data: [
                                {
                                    yAxis: 0,
                                },
                            ],
                            label: {
                                show: false,
                            },
                            lineStyle: {
                                type: 'solid',
                                color: '#6e7079',
                            },
                        },
                    },
                    {
                        name: '分红回报',
                        type: 'bar',
                        stack: 'all',
                        data: dStackingReturnData_FH,
                        itemStyle: {
                            color: '#5e5ce6',
                        },

                        // barWidth: this.ROOTFONTFIZE * (14 / 144)+'px',

                        label: {
                            show: true,
                            position: 'top',
                            fontSize: this.ROOTFONTFIZE * (12 / 144),
                            color: OPTIONDATA.title.textStyle.color,
                            formatter: function (value) {
                                let val = value.value;
                                let num = fnGetMuchDecimals(val);
                                val = Number(val).toFixed(num);
                                if (val == 0) {
                                    return ' ';
                                } else {
                                    return val + '%';
                                }
                            },
                        },
                    },
                    {
                        name: '总体回报',
                        type: 'line',
                        data: dStackingReturnData_ZT,
                        itemStyle: {
                            show: false,
                            width: 0,
                            // color: "#ff7000",
                            color: '#ff5028',
                            opacity: 0.7,
                        },
                        lineStyle: {
                            opacity: 0,
                        },
                        symbol: 'circle',
                        symbolSize: 8,

                        // barWidth: "60px",

                        label: {
                            show: true,
                            position: 'right',
                            fontSize: this.ROOTFONTFIZE * (12 / 144),
                            backgroundColor: '#ff5028',
                            color: 'rgba(255, 255, 255)',
                            padding: 3,
                            borderRadius: 3,
                            formatter: function (value) {
                                let val = value.value;
                                let num = fnGetMuchDecimals(val);
                                val = Number(val).toFixed(num);
                                return val + '%';
                            },
                        },
                    },
                ],
            };

            this.chartColumn4.setOption(option4);
            this.chartColumn4.resize();
            this.loading3 = false;
        },


        initChart1(val) {
            let widthHeight = null;
            let widthWidth = null;
            if (!val) {
                this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
            } else {
                // if (val == '宽高一') {
                //     widthHeight = 600;
                //     widthWidth = 1300;
                // } else if (val == '宽高二') {
                //     widthHeight = 600;
                //     widthWidth = 700;
                // } else {
                //     widthHeight = 400;
                //     widthWidth = 1300;
                // }
                this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1_dialog);
            }
            this.chartColumn1.clear();

            let dataTmp = this.chart1Tmp;
            // X
            let dReitsBenIndex_X = dataTmp.map((node) => {
                return node.tr_date;
            });

            // Y
            let Y1 = Object.keys(dataTmp[0]);
            Y1.shift();

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let theme = this.$store.state.theme;

            let colors = [
                'rgba(255, 87, 51, 1)',
                theme == 'dark' ? 'rgba(242, 209, 201, 1)' : '#a84d38',
                'rgba(170, 171, 174, 1)',
            ];
            colors.unshift(this.$store.state.colors.find((node) => node.name == '公募REITs').value);

            let colorArr = [];
            Y1.forEach((e, idx) => {
                colorArr.push(colors[idx]);
            });

            let FINALLY = [];
            let minData = [];
            Y1.forEach((node) => {
                let obj = {
                    name: node,
                    data: [],
                };

                dataTmp.forEach((e) => {
                    obj.data.push(e[node]);
                });

                minData.push(Math.min(...obj.data));

                FINALLY.push(obj);
            });

            let min = Math.min(...minData) - 0.2;
            min = min.toFixed(1);

            let seriesArr = FINALLY.map((node) => {
                return {
                    name: node.name,
                    data: node.data,
                    type: 'line',
                    smooth: false,
                    itemStyle: {
                        // color: "#5ae8ed",
                    },
                    symbol: 'none',

                    label: {
                        show: false,
                    },
                };
            });
            // console.log("seriesArr",seriesArr);
            // console.log("dReitsBenIndex_X", dReitsBenIndex_X)
            let texta = this.start_date_chart1And2;
            switch (texta) {
                case '3M':
                    texta = '3个月以来';
                    break;
                case 'YTD':
                    texta = '今年以来';
                    break;

                case '6M':
                    texta = '6个月以来';
                    break;

                case '1Y':
                    texta = '1年以来';

                    break;
                case '3Y':
                    texta = '3年以来';
                    break;

                case 'MAX':
                    texta = '上市以来';
                    break;
            }

            let option1 = {
                color: colorArr,
                title: {
                    // 标题设置
                    text: texta + ' 公募REITs及主要对标指数走势对比', // 标题文字
                    textStyle: {
                        color: OPTIONDATA.title.textStyle.color,
                        fontSize: this.ROOTFONTFIZE * (OPTIONDATA.title.textStyle.fontSize / 144),
                    },
                    top: this.ROOTFONTFIZE * (OPTIONDATA.title.top / 144),
                },
                legend: {
                    orient: 'vertical',
                    right: this.ROOTFONTFIZE * (OPTIONDATA.legend.right / 144),
                    top: 'middle',
                    align: 'left',
                    itemGap: this.ROOTFONTFIZE * (20 / 144),
                    itemHeight: this.ROOTFONTFIZE * (5 / 144),
                    textStyle: {
                        fontSize: this.ROOTFONTFIZE * (OPTIONDATA.legend.textStyle.fontSize / 144),
                        width: 80,
                        color: OPTIONDATA.legend.textStyle.color,
                        overflow: 'break',
                    },
                    data: Y1,
                },
                tooltip: {
                    formatter: function (args) {
                        let str = '';
                        str += args[0].axisValueLabel;
                        str += '<br>';
                        args.forEach((node) => {
                            let val = Number(node.value).toFixed(2);
                            str += `${node.marker}${node.seriesName}：${val}`;
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },

                grid: {
                    left: 'left',
                    bottom: this.ROOTFONTFIZE * (OPTIONDATA.grid.bottom / 144),
                    right: this.ROOTFONTFIZE * (120 / 144),
                    containLabel: true,
                },

                xAxis: {
                    splitNumber: 4,
                    data: dReitsBenIndex_X,
                    axisLabel: {
                        fontSize: this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.fontSize / 144),
                        show: true,
                        showMaxLabel: true,
                        margin: this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.margin / 144),
                        // color: "rgba(218, 218, 218, 1)",
                        // color: '#6e7079'
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                    // data: ['1','2','3','4'],
                },
                yAxis: {
                    min: min,
                    axisLabel: {
                        fontSize: this.ROOTFONTFIZE * (OPTIONDATA.yAxis.axisLabel.fontSize / 144),
                        show: true,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                        formatter: function (args) {
                            args = Number(args).toFixed(2);
                            return args;
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.yAxis.splitLine.lineStyle.color,
                        },
                    },
                },

                series: seriesArr,
            };

            if (widthWidth != null) {
                option1['grid']['width'] = this.ROOTFONTFIZE * (widthWidth / 144) + 'px';
                option1['grid']['height'] = this.ROOTFONTFIZE * (widthHeight / 144) + 'px';
            }
            if (val) {
                // option1['title'] = {};
                // option1['grid']['top'] = this.ROOTFONTFIZE * (30 / 144) + 'px';
                // option1['title']['top'] = this.ROOTFONTFIZE * ((OPTIONDATA.title.top - 10) / 144);
            }

            let basicOption = this.$store.state.basicOption;

            this.chartColumn1.setOption(basicOption);
            this.chartColumn1.setOption(option1);

            this.chartColumn1.resize();
            this.loading1 = false;
            // this.funResize()
        },

        // -------------------------------------------------------

        async fnGetReitsAndBenCorr(val) {
            let ben_select = null;
            if (typeof this.select_val_benchmark[0] !== 'string') {
                // 修改一下数组 start
                ben_select = this.select_val_benchmark.map((node) => {
                    return node[1];
                });
                // 修改一下数组 end
            } else {
                ben_select = this.select_val_benchmark;
            }
            // let ben_select = this.select_val_benchmark;
            let str = ben_select.join('&ben_select=');

            let start_date = this.start_date_chart1And2;
            start_date = start_date == 'MAX' ? 'max' : start_date;

            const { data } = await this.$https.get(
                '/api/get_reits_and_ben_corr?ben_select=' + str + '&start_date=' + start_date
            );

            if (data.code !== 200) return this.$message.error(data.msg);

            this.dGetReitsAndBenCorr = data.data;

            // 转换热力图数据
            let data2 = this.dGetReitsAndBenCorr.data;
            this.dGetReitsAndBenCorr_data = [];

            for (let i = 0; i < data2.length; i++) {
                for (let j = data2[i].length - 1; j >= 0; j--) {
                    let arr1 = [];
                    arr1[0] = i;
                    arr1[1] = data2[i].length - 1 - j;
                    arr1[2] = data2[i][j].toFixed(2);

                    this.dGetReitsAndBenCorr_data.push(arr1);
                }
            }

            let columns = this.dGetReitsAndBenCorr.columns.reverse();

            this.dGetReitsAndBenCorrColumns = columns;
            this.$nextTick(() => {
                this.initChart2();
            });
        },

        initChart2(val) {
            let widthHeight = null;
            let widthWidth = null;
            if (!val) {
                widthHeight = 320;
                widthWidth = 320;
                this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2);
            } else {
                if (val == '宽高一') {
                    widthHeight = 470;
                    widthWidth = 1200;
                } else if (val == '宽高二') {
                    widthHeight = 470;
                    widthWidth = 470;
                } else {
                    widthHeight = 250;
                    widthWidth = 1200;
                }
                this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2_dialog);
            }
            this.chartColumn2.clear();

            // this.chartColumn2 = this.$echarts.init(this.$refs.chartColumn2_dialog);
            let dGetReitsAndBenCorr = this.dGetReitsAndBenCorr;
            let dGetReitsAndBenCorr_data = this.dGetReitsAndBenCorr_data;
            let yData = this.dGetReitsAndBenCorrColumns;

            let options_benchmark = this.options_benchmark;

            let arr = dGetReitsAndBenCorr_data.map((node) => {
                return node[node.length - 1];
            });
            let min = Math.min(...arr);
            let max = Math.max(...arr);

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let texta = this.start_date_chart1And2;

            switch (texta) {
                case '3M':
                    texta = '3个月以来';
                    break;
                case 'YTD':
                    texta = '今年以来';
                    break;

                case '6M':
                    texta = '6个月以来';
                    break;

                case '1Y':
                    texta = '1年以来';

                    break;
                case '3Y':
                    texta = '3年以来';
                    break;

                case 'MAX':
                    texta = '上市以来';
                    break;
            }

            let option = {
                title: {
                    // 标题设置
                    text: texta + ' 公募REITs及主要对标指数走势对比', // 标题文字
                    textStyle: {
                        // 标题文字样式设置
                        color: OPTIONDATA.title.textStyle.color,
                        // 标题文字样式设置
                        fontSize: this.ROOTFONTFIZE * (OPTIONDATA.title.textStyle.fontSize / 144),
                    },
                    left: 'center',
                    top: this.ROOTFONTFIZE * (OPTIONDATA.title.top / 144),
                },
                grid: {
                    // 320
                    width: this.ROOTFONTFIZE * (widthWidth / 144) + 'px',
                    height: this.ROOTFONTFIZE * (widthHeight / 144) + 'px',
                    top: this.ROOTFONTFIZE * (55 / 144),
                    left: 'center',
                    bottom: this.ROOTFONTFIZE * (90 / 144),
                },

                xAxis: {
                    data: dGetReitsAndBenCorr.index,
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: this.ROOTFONTFIZE * (14 / 144),
                        rotate: 90,
                        // formatter:function(val){
                        //   return val.split("").join('\n')
                        // },
                        formatter: function (val) {
                            let flag = options_benchmark.some((node) => {
                                return node.value == val;
                            });

                            if (flag || val == '公募REITs') {
                                return val;
                            } else {
                                return val + 'REITS';
                            }
                        },
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    type: 'category',
                    data: yData,
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: OPTIONDATA.yAxis.axisLabel.color,
                        fontSize: this.ROOTFONTFIZE * (14 / 144),
                        margin: this.ROOTFONTFIZE * (115 / 144),
                        textStyle: {
                            align: 'left',
                        },
                        formatter: function (val) {
                            let flag = options_benchmark.some((node) => {
                                return node.value == val;
                            });

                            if (flag || val == '公募REITs') {
                                return val;
                            } else {
                                return val + 'REITS';
                            }
                        },
                    },
                },
                visualMap: {
                    min: min,
                    max: max,
                    calculable: false,
                    orient: 'vertical',
                    text: [max.toFixed(fnGetMuchDecimals(max)), min.toFixed(fnGetMuchDecimals(min))],
                    top: this.ROOTFONTFIZE * (50 / 144),
                    right: '3%',
                    inRange: {
                        color: ['#417f9c', '#eae8e8', '#ca6d55'],
                    },
                    itemHeight: this.ROOTFONTFIZE * (widthHeight / 144),
                    textStyle: {
                        color: '#fff',
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    formatter: function (val) {
                        let num = (parseInt(Math.abs(val)) + '').length >= 2 ? 1 : 2;
                        val = val.toFixed(num);
                        return val;
                    },
                },
                series: [
                    {
                        type: 'heatmap',
                        data: dGetReitsAndBenCorr_data,
                        label: {
                            show: true,
                            color: '#fff',
                            fontSize: this.ROOTFONTFIZE * (10 / 144),
                        },
                        // 强调
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                            label: {
                                fontSize: this.ROOTFONTFIZE * (14 / 144),
                                fontWeight: 'bold',
                            },
                        },
                    },
                ],
            };

            this.chartColumn2.setOption(option);
            this.chartColumn2.resize();
        },


        async fnGetCapsizeReturn() {
            this.loading3 = true;
            let freq_type = this.start_date_chart3And4And5;
            // if (freq_type == "MAX") return
            freq_type = freq_type == 'MAX' ? 'max' : freq_type;
            const { data } = await this.$https.get('/api/capsize_return?freq_type=' + freq_type);

            if (data.code !== 200) return this.$message.error(data.msg);

            this.dCapsizeReturn = data.data;

            // 转换热力图数据
            let data2 = this.dCapsizeReturn.data;
            data2.reverse();
            this.dCapsizeReturn_data = [];

            for (let i = 0; i < data2.length; i++) {
                for (let j = 0; j < data2[i].length; j++) {
                    let arr1 = [];
                    arr1[0] = j;
                    arr1[1] = i;
                    arr1[2] = data2[i][j].toFixed(3);

                    this.dCapsizeReturn_data.push(arr1);
                }
            }

            let arr1 = [];
            this.dCapsizeReturn_data.forEach((node) => {
                arr1.push(node[2]);
            });

            arr1 = arr1.map((node) => {
                return Number(node);
            });

            let minval = Math.min(...arr1);
            let maxval = Math.max(...arr1);

            this.dCapsizeReturn_arr1 = arr1;
            this.dCapsizeReturn_minval = minval;
            this.dCapsizeReturn_maxval = maxval;
            this.$nextTick(() => {
                this.initChart5();
            });
        },

        initChart5() {
            this.chartColumn5 = this.$echarts.init(this.$refs.chartColumn5);

            let dCapsizeReturn = this.dCapsizeReturn;
            let dCapsizeReturn_data = this.dCapsizeReturn_data;

            // console.log(dCapsizeReturn_data, 'dCapsizeReturn_data迪丽热巴');
            // let arr1 = this.dCapsizeReturn_arr1;
            let minval = this.dCapsizeReturn_minval;
            let maxval = this.dCapsizeReturn_maxval;

            let obj = dCapsizeReturn.index;
            let arrTmp1 = Object.values(obj);
            let dCapsizeReturn_index = arrTmp1.reverse();

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let option = {
                grid: {
                    width: this.ROOTFONTFIZE * (160 / 144) + 'px',
                    height: this.ROOTFONTFIZE * (160 / 144) + 'px',
                    top: this.ROOTFONTFIZE * (35 / 144),
                    left: 'center',
                },
                xAxis: {
                    type: 'category',
                    data: dCapsizeReturn.columns,
                    position: 'top',

                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        fontSize: this.ROOTFONTFIZE * (12 / 144),
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    type: 'category',
                    data: dCapsizeReturn_index,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: OPTIONDATA.yAxis.axisLine.lineStyle.color,
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        color: OPTIONDATA.yAxis.axisLabel.color,

                        fontSize: this.ROOTFONTFIZE * (12 / 144),
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'solid',
                            color: OPTIONDATA.yAxis.axisLine.lineStyle.color,
                        },
                    },
                },
                visualMap: {
                    min: minval,
                    max: maxval,
                    top: this.ROOTFONTFIZE * (35 / 144),
                    right: '20%',
                    orient: 'vertical',
                    calculable: false,
                    inRange: {
                        // color: ["#63e6e2", "#6088e5"],
                        color: ['#fefffe', 'rgba(90, 232, 237, 1)'],
                        // color: ["#46ed81"],
                    },

                    textStyle: {
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    formatter: function (value) {
                        let val = Number(value) * 100;
                        let num = (parseInt(Math.abs(val)) + '').length >= 2 ? 1 : 2;
                        val = val.toFixed(num);
                        return val + '%';
                    },

                    // bottom: "15%",
                },

                series: [
                    {
                        name: 'Punch Card',
                        type: 'heatmap',
                        data: dCapsizeReturn_data,
                        label: {
                            show: true,
                            color: '#333',
                            formatter: function (param) {
                                let val = param.value[2] * 100;
                                let num = (parseInt(Math.abs(val)) + '').length >= 2 ? 1 : 2;
                                val = val.toFixed(num) + '%';

                                return val;
                            },
                        },
                        // 强调
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                            label: {
                                fontSize: this.ROOTFONTFIZE * (14 / 144),
                                fontWeight: 'bold',
                            },
                        },
                    },
                ],
            };

            this.chartColumn5.setOption(option);
            this.chartColumn5.resize();
        },
        fnCTimeButtons_select_val_chart6(val) {
            this.start_date_chart6 = val;
            this.fnGetCalSingleReitIndex();
        },
        async fnGetCalSingleReitIndex() {
            this.loading2 = true;
            // 待处理  要在这里做个判断
            // let ben_select = ["公用指数", "沪深300", "CN10YR"];
            let start_date = this.start_date_chart6;
            start_date = start_date == 'MAX' ? 'max' : start_date;

            const { data } = await this.$https.get('/api/cal_single_reit_index?start_date=' + start_date);
            if (data.code !== 200) return this.$message.error(data.msg);

            this.dCalSingleReitIndex = data.data;
            let dataTmp = data.data;

            // 步骤1：提取x轴数据
            let arrX = [];
            dataTmp.forEach((node) => {
                arrX.push(node.tr_date);
            });

            // 步骤2：提取值作为y轴数据，组成一组数据
            // let arr2 = Object.keys(dataTmp[0]);
            // arr2.shift(); // 改变一下这个顺序即可
            let arr2 = Object.entries(dataTmp[dataTmp.length - 1]);
            arr2.shift();

            function compare(val1, val2) {
                return val2[1] - val1[1];
            }
            arr2.sort(compare);
            arr2 = arr2.map((node) => {
                return node[0];
            });

            let FINALLYDATA = [];
            arr2.forEach((node) => {
                let obj = {
                    key: node,
                    data: [],
                };
                arrX.forEach((item) => {
                    dataTmp.forEach((e) => {
                        if (e.tr_date == item) {
                            obj.data.push(e[node]);
                        }
                    });
                });

                FINALLYDATA.push(obj);
            });

            let FINALLYDATA_AFTER = [];
            arr2.forEach((node) => {
                let obj = {
                    name: node,
                    value: FINALLYDATA,
                };
                FINALLYDATA_AFTER.push(obj);
            });

            // FINALLYDATA_AFTER = FINALLYDATA_AFTER.slice(0, 20);

            // this.dCalSingleReitIndex_Ys = arr3;
            this.dCalSingleReitIndex_Ys = [];
            this.dCalSingleReitIndex_Ys = FINALLYDATA_AFTER;

            this.chart6_arrX = arrX;
            this.initChart6();
        },

        initChart6() {
            let FINALLYDATA_AFTER = this.dCalSingleReitIndex_Ys;
            let arrX = this.chart6_arrX;

            let MAP_L2 = this.$store.state.MAP_L2;
            let COLORSARRAY = this.$store.state.colors;
            //
            let CODELIST = this.$store.state.CODELIST;

            let theme = this.$store.state.theme;

            setTimeout(() => {
                FINALLYDATA_AFTER.forEach((item, index) => {
                    this.$nextTick(() => {
                        const chartInstance = echarts.init(document.getElementById(item.name));
                        chartInstance.clear();

                        let seriesArr = item.value.map((node) => {
                            return {
                                name: node.key,
                                data: node.data,
                                type: 'line',
                                smooth: false,
                                symbol: 'none',
                                tooltip: {
                                    show: node.key == item.name ? true : false,
                                },
                            };
                        });

                        let colors = [];
                        item.value.forEach((node, idx) => {
                            if (item.name == node.key) {
                                let valobj = CODELIST.filter((item2) => {
                                    return item2.reitCode == node.key;
                                });
                                let val = valobj[0].reitsTypeL2;
                                let obj = COLORSARRAY.filter((node) => {
                                    return node.name == val;
                                });

                                colors.push(obj[0].value);
                            } else {
                                if (theme == 'dark') {
                                    colors.push('rgba(118, 118, 118, 0.1)');
                                } else {
                                    colors.push('rgba(196, 196, 196, 0.1)');
                                }
                            }
                        });

                        // let text = this.MAP[item.name];
                        let MAP = CODELIST.filter((node) => {
                            return node.reitCode == item.name;
                        });
                        let text = MAP[0].reitsName;
                        let CODENAME = this.$store.state.CODENAME;

                        let OPTIONDATA =
                            this.$store.state.theme == 'dark'
                                ? this.$store.state.OPTIONDATA
                                : this.$store.state.OPTIONDATA_light;

                        let option = {
                            tooltip: {
                                trigger: 'axis',
                                confine: true,
                                triggerOn: 'mousemove|click', // 触发时机, click代表点击, mouseOver代表鼠标移过
                                formatter: function (val) {
                                    let str = '';
                                    val.forEach((node) => {
                                        let val = Number(node.value).toFixed(2);

                                        str += `${node.axisValueLabel}`;
                                        str += '<br>';
                                        // str += `${node.marker}${node.seriesName}:   ${val}`;
                                        str += `${node.marker}${CODENAME[node.seriesName]}:   ${val}`;
                                    });

                                    return str;
                                },
                            },
                            title: {
                                text: text, // 标题文字
                                textStyle: {
                                    color: OPTIONDATA.title.textStyle.color,
                                    fontSize: this.ROOTFONTFIZE * (16 / 144),
                                },
                                top: this.ROOTFONTFIZE * (20 / 144),
                                left: this.ROOTFONTFIZE * (20 / 144),
                            },
                            color: colors,
                            xAxis: {
                                type: 'category',
                                data: arrX,
                                axisTick: {
                                    show: false,
                                },
                                axisLine: {
                                    show: true,
                                },
                                axisLabel: {
                                    show: false,
                                },
                            },
                            yAxis: {
                                type: 'value',
                                axisLine: {
                                    show: true,
                                },
                                axisTick: {
                                    show: false,
                                },
                                splitLine: {
                                    show: false,
                                },
                                axisLabel: {
                                    show: true,
                                    color: OPTIONDATA.xAxis.axisLabel.color,
                                    fontSize: this.ROOTFONTFIZE * (14 / 144),
                                },
                            },
                            series: seriesArr,
                        };

                        chartInstance.setOption(option);
                        this.loading2 = false;
                    });
                });
            }, 500);
        },


        async fnGetOnlyReitsIndex() {
            // 待处理  要在这里做个判断
            // let ben_select = ["公用指数", "沪深300", "CN10YR"];
            let start_date = this.start_date_chart3And4And5;
            start_date = start_date == 'MAX' ? 'max' : start_date;

            let level = this.select_val_level;

            const { data } = await this.$https.get(
                '/api/get_only_reits_index?start_date=' + start_date + '&level=' + level
            );

            if (data.code !== 200) return this.$message.error(data.msg);

            this.chart3_data = data.data;


            this.dataCart2 = data.data;
            if (level == "L1") {
                this.jsonCart2 = {
                    日期: 'tr_date',
                    公募REITs: '公募REITs',
                    产权类: '产权类',
                    经营权类: '经营权类',
                };
            } else if (level == "L2") {
                this.jsonCart2 = {
                    日期: 'tr_date',
                    产业园: '产业园',
                    保障性住房: '保障性住房',
                    市政生态: '市政生态',
                    收费公路: '收费公路',
                    消费: '消费',
                    物流仓储: '物流仓储',
                    能源: '能源',
                };
            } else {
                this.jsonCart2 = {
                    日期: 'tr_date',
                    公募REITs: '公募REITs',
                    产权类: '产权类',
                    经营权类: '经营权类',
                    产业园: '产业园',
                    保障性住房: '保障性住房',
                    市政生态: '市政生态',
                    收费公路: '收费公路',
                    消费: '消费',
                    物流仓储: '物流仓储',
                    能源: '能源',
                };
            }


            this.initChart3();
            this.fnGetStackingReturnData();
        },

        initChart3(val) {
            let widthHeight = null;
            let widthWidth = null;
            if (!val) {
                this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
            } else {
                // if (val == '宽高一') {
                //     widthHeight = 520;
                //     widthWidth = 1300;
                // } else if (val == '宽高二') {
                //     widthHeight = 520;
                //     widthWidth = 700;
                // } else {
                //     widthHeight = 300;
                //     widthWidth = 1300;
                // }
                this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3_dialog);
            }
            this.chartColumn3.clear();

            // let data = this.chart3_data;
            let data = JSON.parse(JSON.stringify(this.chart3_data));
            console.log('data', data);
            let X = data.map((node) => {
                return node['tr_date'];
            });
            // console.log('X', X);
            this.sOnlyReitsIndexDDL = X[X.length - 1];
            // console.log('this.sOnlyReitsIndexDDL', this.sOnlyReitsIndexDDL);

            let arr = this.sOnlyReitsIndexDDL.split('-');
            this.sOnlyReitsIndexDDL = arr[0] + '年' + arr[1] + '月' + arr[2] + '日';
            let Y = data.map((node) => {
                delete node['tr_date'];
                return node;
            });
            let data2 = Object.keys(Y[0]);
            let datatmp3 = [];
            let DATA = data2;
            if (this.select_val_level == 'all' || this.select_val_level == 'L2') {
                let JYQL = [];
                let CQL = [];

                data2.forEach((node) => {
                    let obj = null;
                    this.category_cascader_options.forEach((e) => {
                        let val = e.data.includes(node);
                        if (val) {
                            obj = e;
                        }
                    });

                    if (obj && obj.name == '产权类') {
                        CQL.push(node);
                    }

                    if (obj && obj.name == '经营权类') {
                        JYQL.push(node);
                    }
                });

                if (data2.indexOf('公募REITs') > 0) {
                    datatmp3.push('公募REITs');
                }

                if (data2.indexOf('产权类') > 0) {
                    datatmp3.push('产权类');
                }

                if (data2.indexOf('经营权类') > 0) {
                    datatmp3.push('经营权类');
                }
                datatmp3 = [...datatmp3, ...CQL];
                datatmp3 = [...datatmp3, ...JYQL];
                DATA = datatmp3;
            } else {
                DATA = ['公募REITs', '产权类', '经营权类'];
            }

            let dataFINALY = [];
            DATA.forEach((node) => {
                let obj = {
                    name: node,
                    data: [],
                };
                Y.forEach((e) => {
                    obj.data.push(e[node]);
                });
                dataFINALY.push(obj);
            });

            let NAME = dataFINALY.map((node) => {
                return node.name;
            });

            let colors = this.$store.state.colors;

            let colorArr = [];
            NAME.forEach((e) => {
                colors.forEach((node) => {
                    if (e == node.name) {
                        colorArr.push(node.value);
                    }
                });
            });

            let seriesArr = dataFINALY.map((node) => {
                return {
                    name: node.name,
                    data: node.data,
                    type: 'line',
                    smooth: false,
                    symbol: 'none',
                    label: {
                        show: false,
                        position: 'top',
                    },
                };
            });

            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let texta = this.start_date_chart3And4And5;

            switch (texta) {
                case '3M':
                    texta = '3个月以来';
                    break;
                case 'YTD':
                    texta = '今年以来';
                    break;

                case '6M':
                    texta = '6个月以来';
                    break;

                case '1Y':
                    texta = '1年以来';

                    break;
                case '3Y':
                    texta = '3年以来';
                    break;

                case 'MAX':
                    texta = '上市以来';
                    break;
            }

            this.chartColumn5_title = texta;

            let option = {
                color: colorArr,
                title: {
                    // 标题设置
                    text: texta + ' 公募REITs及主要对标指数走势对比', // 标题文字
                    textStyle: {
                        // 标题文字样式设置
                        color: OPTIONDATA.title.textStyle.color,
                        fontSize: this.ROOTFONTFIZE * (OPTIONDATA.title.textStyle.fontSize / 144),
                    },
                    left: 'center',
                    top: this.ROOTFONTFIZE * (OPTIONDATA.title.top / 144),
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: function (args) {
                        let str = '';
                        str += args[0].axisValueLabel;
                        str += '<br>';
                        args.forEach((node) => {
                            let val = Number(node.value).toFixed(2);
                            str += `${node.marker}${node.seriesName}：${val}`;
                            str += '<br>';

                            return str;
                        });
                        return str;
                    },
                },
                grid: {
                    left: 'left',
                    bottom: this.ROOTFONTFIZE * (OPTIONDATA.grid.bottom / 144),
                    right: this.ROOTFONTFIZE * (120 / 144),
                    containLabel: true,
                },

                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    //  interval: "2",  ???
                    splitNumber: 4,
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLabel: {
                        fontSize: this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.fontSize / 144),
                        show: true,
                        showMaxLabel: true,
                        margin: this.ROOTFONTFIZE * (OPTIONDATA.xAxis.axisLabel.margin / 144),
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                    data: X,
                },
                yAxis: {
                    scale: true,
                    type: 'value',
                    axisLine: {
                        show: false,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: this.ROOTFONTFIZE * (OPTIONDATA.yAxis.axisLabel.fontSize / 144),
                        show: true,
                        color: OPTIONDATA.yAxis.axisLabel.color,
                        formatter: function (args) {
                            args = Number(args).toFixed(2);
                            return args;
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed',
                            color: OPTIONDATA.yAxis.splitLine.lineStyle.color,
                        },
                    },
                },
                legend: {
                    show: true,
                    icon: 'roundRect',
                    orient: 'vertical',
                    right: this.ROOTFONTFIZE * (10 / 144),
                    top: 'middle',
                    align: 'left',
                    itemGap: this.ROOTFONTFIZE * (10 / 144),
                    itemHeight: this.ROOTFONTFIZE * (5 / 144),
                    padding: [0, 0, 0, 0],
                    textStyle: {
                        color: OPTIONDATA.legend.textStyle.color,
                        fontSize: this.ROOTFONTFIZE * (OPTIONDATA.legend.textStyle.fontSize / 144),
                    },
                    data: NAME,
                },
                series: seriesArr,
            };
            if (widthWidth != null) {
                option['grid']['width'] = this.ROOTFONTFIZE * (widthWidth / 144) + 'px';
                option['grid']['height'] = this.ROOTFONTFIZE * (widthHeight / 144) + 'px';
            }
            this.chart3_option = option;
            this.chartColumn3.setOption(option);
            this.chartColumn3.resize();
        },
        fnDropdown1(val) {
            if (val == 0) {
                let conf = this.$store.state.popUpWindowConfi[val];
                this.width_dialog = conf.width_dialog;
                this.top_dialog = conf.top_dialog;
                this.height_dialog = conf.height_dialog;
                this.popUpWindowIndex = val;
                this.height_chart = conf.height_chart;
                this.$nextTick(() => {
                    this.initChart1('宽高一');
                });
            } else if (val == 1) {
                let conf = this.$store.state.popUpWindowConfi[val];
                this.width_dialog = conf.width_dialog;
                this.top_dialog = conf.top_dialog;
                this.height_dialog = conf.height_dialog;
                this.popUpWindowIndex = val;
                this.height_chart = conf.height_chart;
                this.$nextTick(() => {
                    this.initChart1('宽高二');
                });
            } else {
                let conf = this.$store.state.popUpWindowConfi[val];
                this.width_dialog = conf.width_dialog;
                this.top_dialog = conf.top_dialog;
                this.height_dialog = conf.height_dialog;
                this.popUpWindowIndex = val;
                this.height_chart = conf.height_chart;
                this.$nextTick(() => {
                    this.initChart1('宽高三');
                });
            }
        },
        // 放大菜单
        fnDropdown2(val) {
            if (val == 0) {
                let conf = this.$store.state.popUpWindowConfi[val];
                this.width_dialog = conf.width_dialog;
                this.top_dialog = conf.top_dialog;
                this.height_dialog = conf.height_dialog;
                this.popUpWindowIndex = val;
                this.height_chart = conf.height_chart;
                this.$nextTick(() => {
                    this.initChart2('宽高一');
                });
            } else if (val == 1) {
                let conf = this.$store.state.popUpWindowConfi[val];
                this.width_dialog = conf.width_dialog;
                this.top_dialog = conf.top_dialog;
                this.height_dialog = conf.height_dialog;
                this.popUpWindowIndex = val;
                this.height_chart = conf.height_chart;
                this.$nextTick(() => {
                    this.initChart2('宽高二');
                });
            } else {
                let conf = this.$store.state.popUpWindowConfi[val];
                this.width_dialog = conf.width_dialog;
                this.top_dialog = conf.top_dialog;
                this.height_dialog = conf.height_dialog;
                this.popUpWindowIndex = val;
                this.height_chart = conf.height_chart;
                this.$nextTick(() => {
                    this.initChart2('宽高三');
                });
            }
        },
        fnDropdown3(val) {
            if (val == 0) {
                let conf = this.$store.state.popUpWindowConfi[val];
                this.width_dialog = conf.width_dialog;
                this.top_dialog = conf.top_dialog;
                this.height_dialog = conf.height_dialog;
                this.popUpWindowIndex = val;
                this.height_chart = conf.height_chart;
                this.$nextTick(() => {
                    this.initChart3('宽高一');
                });
            } else if (val == 1) {
                let conf = this.$store.state.popUpWindowConfi[val];
                this.width_dialog = conf.width_dialog;
                this.top_dialog = conf.top_dialog;
                this.height_dialog = conf.height_dialog;
                this.popUpWindowIndex = val;
                this.height_chart = conf.height_chart;
                this.$nextTick(() => {
                    this.initChart3('宽高二');
                });
            } else {
                let conf = this.$store.state.popUpWindowConfi[val];
                this.width_dialog = conf.width_dialog;
                this.top_dialog = conf.top_dialog;
                this.height_dialog = conf.height_dialog;
                this.popUpWindowIndex = val;
                this.height_chart = conf.height_chart;
                this.$nextTick(() => {
                    this.initChart3('宽高三');
                });
            }
        },

        // ------------------------------------------------------------------------------------------

        // chart3和4和5
        fnCTimeButtons_select_val_chart3And4And5(val) {
            this.start_date_chart3And4And5 = val;
            this.fnGetOnlyReitsIndex();
            this.fnGetCapsizeReturn();
        },
        fnCSelect_chart3And4And5(val) {
            this.select_val_level = val;
            this.fnGetOnlyReitsIndex();
        },

        fnSetOption() {
            let OPTIONDATA =
                this.$store.state.theme == 'dark'
                    ? this.$store.state.OPTIONDATA
                    : this.$store.state.OPTIONDATA_light;

            let option = {
                title: {
                    textStyle: {
                        color: OPTIONDATA.title.textStyle.color,
                    },
                },
                xAxis: {
                    axisLabel: {
                        color: OPTIONDATA.xAxis.axisLabel.color,
                    },
                },
                yAxis: {
                    axisLabel: {
                        color: OPTIONDATA.yAxis.axisLabel.color,
                    },
                    splitLine: {
                        lineStyle: {
                            color: OPTIONDATA.yAxis.splitLine.lineStyle.color,
                        },
                    },
                },
                legend: {
                    textStyle: {
                        color: OPTIONDATA.legend.textStyle.color,
                    },
                },
            };

            this.chartColumn3.setOption(option);
            this.chartColumn3.resize();
        },


    },
    computed: {
        cssVars() {
            return {
                '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
                '--TABPANEHEIGHT': this.TABPANEHEIGHT + 'px',
            };
        },
        currentTheme() {
            return this.$store.state.theme; // 从Vuex中获取当前的主题值
        },
    },
    watch: {
        currentTheme(newVal, oldVal) {
            this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
            this.$nextTick(() => {
                if (!this.is_reload) {
                    return;
                }
                this.initChart1();
                this.initChart2();
                this.initChart4();
                this.initChart5();
                this.initChart6();
                this.fnSetOption();
            });
        },
    },
};
</script>

<style lang="less" scoped>
.overall {
    background-color: var(--charts_bgc);
    height: auto;
    // margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144));
    padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    margin-bottom: calc(var(--ROOTFONTFIZE) * (39 / 144));
    padding-right: calc(var(--ROOTFONTFIZE) * (16 / 144));
    padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    box-sizing: border-box;
}

.overall2 {
    background-color: var(--charts_bgc);

    height: auto;
    margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144));
    padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    padding-right: calc(var(--ROOTFONTFIZE) * (16 / 144));
    padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    box-sizing: border-box;
}

// -----------------------------------------------------------------------------------------------
.chart_title {
    color: var(--chart_title);
    font-size: calc(var(--ROOTFONTFIZE) * (20 / 144));
    font-weight: 700;
    // border-left: 5px solid #1573fe;
    border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
    padding-left: 18px;
    padding-left: calc(var(--ROOTFONTFIZE) * (18 / 144));
    height: calc(var(--ROOTFONTFIZE) * (28 / 144));
    display: flex;
    align-items: center;
}

.chart_title span {
    color: #1573fe;
}


.chart1_content_top_tit {
    writing-mode: vertical-rl;
    text-align: center;
    color: rgba(140, 140, 140, 1);
    color: rgba(218, 218, 218, 1);
    color: var(--stock_area_item_color2);
    font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
    font-weight: 400;
    margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.chart1_content_bottom {
    padding-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
    color: rgba(158, 158, 158, 1);
    font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
}

.downloadBtns {
    position: absolute;
    right: 10px;
    top: 28px;
}

.icon-fangda1 {
    position: absolute;
    right: 20px;
    top: 100px;
}

.icon-fangda3 {
    position: absolute;
    right: 20px !important;
}

.btnArea {
    margin-top: calc(var(--ROOTFONTFIZE) * (27 / 144));
    display: flex;
    justify-content: center;
    margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    margin-left: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.ml80 {
    margin-left: calc(var(--ROOTFONTFIZE) * (80 / 144));
}

.p10 {
    padding: calc(var(--ROOTFONTFIZE) * (0 / 144)) calc(var(--ROOTFONTFIZE) * (20 / 144));
    justify-content: start;
}

.btnArea2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    position: relative;
    // padding-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.canvasArea {
    width: 100%;
    height: 100%;
}

.canvasArea2 {
    // width: 100%;
    height: calc(var(--ROOTFONTFIZE) * (600 / 144));
}

.echarts_AREA {
    padding-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.canvasArea3 {
    width: 16%;
    margin-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
    border: none;
}

.chart_content {
    margin-left: calc(var(--ROOTFONTFIZE) * (18 / 144));
    border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
    position: relative;
}


.chart1,
.chart2 {
    height: calc(var(--ROOTFONTFIZE) * (520 / 144));
}

.chart3 {
    height: calc(var(--ROOTFONTFIZE) * (520 / 144));
}

.chart1_content_top {
    display: flex;
}

.overall2Right {}


.chart4 {
    height: calc(var(--ROOTFONTFIZE) * (250 / 144));
}

.chart5 {
    height: calc(var(--ROOTFONTFIZE) * (230 / 144));
    margin-top: calc(var(--ROOTFONTFIZE) * (26 / 144));
}

.chart5_tit {
    float: left;
    margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
    margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
    color: var(--stock_area_item_color2);
    font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
    font-weight: 600;
}

.chart6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chart3_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>
