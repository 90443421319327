<template>
  <div class="module">
    <div class="card">
      <div class="card_tit">
        <!-- 公募 REITs 内含报酬率走势对比 -->
        <template v-if="irr_call_mode_list_sel == 'IRR'"> 板块平均(内含报酬率)走势对比 </template>
        <template v-else> 板块平均(计算真实价值比例)走势对比 </template>
        <div class="downloadBtns" v-show="isVip">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads2"
            @fnButtondownload="fnButtonDownload1" />
          <span style="display: none">
            <download-excel
              id="downloadExcel7_7"
              :data="dCalIrr_down"
              :fields="json_fields_column8"
              :header="column8Title"
              :name="column8Title + '.xls'">
            </download-excel>
          </span>
        </div>
      </div>

      <div class="card_history_top" v-show="isVip">
        <div style="margin-left: -20px">
          <CButtonsSeparate
            @Cbtns_sep="refreshIrrCallMode"
            :dButtons1="irr_call_mode_list"
            :dButtons1_current="irr_call_mode_list_sel" />
        </div>
        <div style="margin-left: 15px">
          <CSelect
            @CSelect_select_val="refreshIrrRe"
            :default="irr_re_list_sel"
            :mini="true"
            :clearable="false"
            :auto="true"
            :options="irr_re_list"
            >设置要求回报</CSelect
          >
        </div>
        <div style="margin-left: 15px">
          <CSelect
            @CSelect_select_val="refreshIrrIncreaseMode"
            :default="irr_increase_mode_list_sel"
            :clearable="false"
            :auto="true"
            :options="irr_increase_mode_list"
            >设置增速</CSelect
          >
        </div>
        <div style="margin-left: 15px">
          <CCascader
            :default="category_cascader_val_chart8"
            :checkStrictly="true"
            :multiple="true"
            :collapsetags="true"
            @CCascader_select_val="fnCCascader_select_val_chart8"
            :middle="true"
            :options="category_cascader_valuationTable"
            >选择类别
          </CCascader>
        </div>
        <div class="abslouteChecked">
          <span class="abslouteChecked_span">显示10年国债Yield Spread</span>
          <el-checkbox
            :disabled="disabled_chart8"
            @change="fnGetCalIrr"
            v-model="summaryChecked"></el-checkbox>
        </div>
        <div>
          <CTimeButtons :default="start_date_chart8" @CTimeButtons_select_val="refreshIrrTime" />
        </div>
      </div>

      <div v-show="isVip" class="main historymain echarts_area display_none">
        <span @click="fnOpenDialog5" class="iconfont icon-fangda icon-fangda2"></span>
        <div
          v-loading="loading_chart8"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="canvasArea"
          id="chartColumn8"
          ref="chartColumn8"
          style="width: 100%; height: 500px"></div>
      </div>

      <div v-show="!isVip" class="noVip">
        <i class="iconfont icon-taocan-suoxiao"></i>
        抱歉，当前内容只能VIP客户查看
        <el-button size="small" class="openBtn">联系管理员开通</el-button>
      </div>

      <div class="card_tit" style="margin-top: 20px">
        <template v-if="irr_call_mode_list_sel == 'IRR'"> 板块内个股(内含报酬率)走势对比 </template>
        <template v-else> 板块内个股(计算真实价值比例)走势对比 </template>
        <div class="downloadBtns" v-show="isVip">
          <CButtonsDownload
            :dButtonsDownloads="dButtonsDownloads2"
            @fnButtondownload="fnButtonDownload2" />
          <span style="display: none">
            <download-excel
              id="downloadExcel7_8"
              :data="data_column8"
              :fields="json_fields_column9"
              :header="column9Title"
              :name="column9Title + '.xls'">
            </download-excel>
          </span>
        </div>
      </div>

      <div v-show="isVip" class="main historymain echarts_area" style="margin-top: 20px">
        <span @click="fnOpenDialog" class="iconfont icon-fangda icon-fangda5"></span>
        <div
          v-loading="loading_chart9"
          element-loading-text="数据量巨大，正在计算中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          class="canvasArea"
          ref="chartColumn9"
          style="width: 100%; height: 500px"></div>
        <div class="DDLINFO">数据截止日期：{{ DDL_DATE }}</div>
      </div>

      <div v-show="!isVip" class="noVip">
        <i class="iconfont icon-taocan-suoxiao"></i>
        抱歉，当前内容只能VIP客户查看
        <el-button size="small" class="openBtn">联系管理员开通</el-button>
      </div>
    </div>

    <CDialog ref="CDialog" :DIALOGHEIGHT="height_dialog" :dialogVisible="dialogVisible5">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible5"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown4">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div :style="{ height: height_chart }">
          <div
            v-loading="loading_chart8"
            element-loading-text="数据量巨大，正在计算中..."
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            class="canvasArea"
            id="chartColumn8"
            ref="chartColumn8"
            style="width: 100%; height: 100%"></div>
        </div>
      </el-dialog>
    </CDialog>

    <CDialog ref="CDialog" :DIALOGHEIGHT="height_dialog" :dialogVisible="dialogVisible">
      <el-dialog
        title="葱花投研"
        :visible.sync="dialogVisible"
        :width="width_dialog"
        :top="top_dialog">
        <div>
          <el-dropdown size="mini" trigger="click" type="primary" @command="fnDropdown2">
            <span class="el-dropdown-link">
              {{ popUpWindowDatas[popUpWindowIndex]
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="idx" v-for="(node, idx) in popUpWindowDatas" :key="idx">
                {{ node }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="historymain2">
          <div :style="{ height: height_chart }">
            <div
              class="canvasArea"
              ref="chartColumn9_dialog"
              style="width: 100%; height: 100%"></div>
            <div class="DDLINFO">数据截止日期：{{ DDL_DATE }}</div>
          </div>
        </div>
      </el-dialog>
    </CDialog>
  </div>
</template>

<script>
import CSelect from '@/components/Basic/CSelect';
import CCategoryButton from '@/components/Basic/CCategoryButton';
import CCascader from '@/components/Basic/CCascader';
import CTimeButtons from '@/components/Basic/CTimeButtons';
import CotherButtons from '@/components/Basic/CotherButtons';
import CButtonsSeparate from '@/components/Basic/CButtons_separate';
import CButtonsDownload from '@/components/Basic/CButtons_download';
import CDialog from '@/components/Basic/CDialog';

import html2canvas from 'html2canvas';
import summer from './summer.vue';
import irr from './irr.vue';

import {
  fnReturnTableStyle,
  fnGetLevelName,
  fnDownloadBgc,
  // fnComputeTime,
} from '@/utils/util';

export default {
  computed: {},
  created() {
    // this.fnGetL1();
    // let name = localStorage.getItem('activeName');
    // this.handleClick({
    //     name: name
    // });
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);

    this.chartColumn4 && this.chartColumn4.dispose();
    this.chartColumn5 && this.chartColumn5.dispose();
    this.chartColumn6 && this.chartColumn6.dispose();
    this.chartColumn7 && this.chartColumn7.dispose();
    this.chartColumn8 && this.chartColumn8.dispose();
    this.chartColumn9 && this.chartColumn9.dispose();
  },
  data() {
    return {
      sCurrentTheme: this.$store.state.theme == 'dark' ? true : false,

      // chart8
      category_cascader_val_chart8: '公募REITs',
      start_date_chart8: '3M',
      DDL_DATE: '',
      disabled_chart8: false,
      color_Y: '',
      dCalIrr: [],
      dCalIrr_down: [],

      // chart9
      chart8_title: '',
      chart9_title: '',
      dCalIrrSingle: [],
      dCalIrrSingleColor: '',

      isVip: false,

      dButtonsDownloads2: [
        {
          id: '241053',
          name: '下载图片',
        },
        {
          id: '241049',
          name: '下载表格',
        },
      ],

      irr_re_list: [
        {
          label: '4%',
          value: '0.04',
        },
        {
          label: '5%',
          value: '0.05',
        },
        {
          label: '6%',
          value: '0.06',
        },
      ],
      irr_re_list_sel: '0.04',
      //ind:行业，single:个股，no：无增速
      irr_increase_mode_list: [
        {
          label: '行业历史增速',
          value: 'ind',
        },
        {
          label: '个股历史增速',
          value: 'single',
        },
        {
          label: '无增速',
          value: 'no',
        },
      ],
      irr_increase_mode_list_sel: 'ind',

      irr_call_mode_list: ['IRR', 'NPV'],
      irr_call_mode_list_sel: 'IRR',

      valuation_chart7: 'PB',
      category_options_valuationTable: [],

      category_cascader_valuationTable: JSON.parse(localStorage.getItem('CATEGORYDATA')),
      json_fields_column8: {},

      summaryChecked: false,

      data_column8: null,
      column8Title: '',
      column9Title: '',
      json_fields_column9: {},

      loading_chart8: false,
      loading_chart9: false,
      dialogVisible: false,

      width_dialog: '80%',

      height_dialog: '73vh',
      height_chart: '71vh',
      popUpWindowDatas: this.$store.state.popUpWindowDatas,
      popUpWindowIndex: 0,
      top_dialog: '5vh',

      dialogVisible5: false,
    };
  },
  methods: {
    funResize() {
      this.$nextTick(() => {
        if (this.chartColumn4) {
          this.chartColumn4.resize();
        }
        if (this.chartColumn5) {
          this.$nextTick(() => {
            this.initChart5();
          });
        }
        if (this.chartColumn6) {
          this.chartColumn6.resize();
        }
        if (this.chartColumn3) {
          this.chartColumn3.resize();
        }
        if (this.chartColumn7) {
          this.chartColumn7.resize();
        }
        if (this.chartColumn8) {
          this.chartColumn8.resize();
        }
        if (this.chartColumn9) {
          this.chartColumn9.resize();
        }
      });
    },

    fnGetTheme() {
      this.sCurrentTheme = this.$store.state.theme == 'dark' ? true : false;
    },

    // 获取当前屏幕宽度
    fnGetWidth() {
      var element = document.getElementById('rootchart');
      let ROOTFONTFIZE = element.offsetWidth;
      ROOTFONTFIZE = ROOTFONTFIZE > 1440 ? 1440 : ROOTFONTFIZE;
      ROOTFONTFIZE = ROOTFONTFIZE < 1440 ? Number(ROOTFONTFIZE) - 200 : ROOTFONTFIZE;

      this.ROOTFONTFIZE = ROOTFONTFIZE / 10;

      window.sessionStorage.setItem('ROOTFONTFIZE', this.ROOTFONTFIZE);

      let OFFSETHEIGHT = element.offsetHeight;
      this.TABPANEHEIGHT = OFFSETHEIGHT - 135;
      this.OFFSETHEIGHT = OFFSETHEIGHT;

      return;
    },

    refreshIrrCallMode(val) {
      this.irr_call_mode_list_sel = val;

      if (val == 'NPV') {
        this.disabled_chart8 = true;
        this.summaryChecked = false;
      } else {
        if (
          this.category_cascader_val_chart8 == '公募REITs' ||
          this.category_cascader_val_chart8.length == 1
        ) {
          this.disabled_chart8 = false;
          this.summaryChecked = false;
        }
      }
      this.fnGetCalIrr();
    },

    refreshIrrRe(val) {
      this.irr_re_list_sel = val;
      this.fnGetCalIrr();
    },

    refreshIrrIncreaseMode(val) {
      this.irr_increase_mode_list_sel = val;
      this.fnGetCalIrr();
    },

    refreshIrrTime(val) {
      this.start_date_chart8 = val;
      this.fnGetCalIrr();
    },

    async handleClick(e) {
      console.log('irr handleClick', e);

      this.activeName = e.name;
      localStorage.setItem('activeName', this.activeName);
      let activeName = e.name;
      console.log('handleClick', activeName);

      if (activeName == 'fifth') {
        this.isVip = fnGetLevelName() == 'VIP客户' ? true : false;
        this.$eventBus.$emit('setBreadcrumbs', 'IRR/NPV走势');
        if (!this.isVip) return;
        this.fnGetCalIrr();
      }
    },

    initChart9(val) {
      if (!val) {
        this.chartColumn9 = this.$echarts.init(this.$refs.chartColumn9);
      } else {
        this.chartColumn9 = this.$echarts.init(this.$refs.chartColumn9_dialog);
      }
      this.chartColumn9.clear();

      let dCalIrrSingle = this.dCalIrrSingle;
      // 1，找出时间(x轴)
      let DATE = dCalIrrSingle.map((node) => {
        return node.tr_date;
      });

      DATE = [...new Set(DATE)];
      this.DDL_DATE = DATE[DATE.length - 1];

      let arrTmp2 = this.DDL_DATE.split('-');
      this.DDL_DATE = arrTmp2[0] + '年' + arrTmp2[1] + '月' + arrTmp2[2] + '日';

      // console.log("initChart9 DATE", DATE);
      // console.log("initChart9 dCalIrrSingle", dCalIrrSingle);
      // 2.
      let DATA = [];
      DATE.forEach((node, idx) => {
        let obj = {
          name: node, // X轴
          data: [], // 数据
          data2: [], // 散点数据
          max: 0, // 一天中的最大值
          min: 0, // 一天中的最小值
          Y: 0, //  柱图颜色区域
          T: 0,
          two: 0,
          eight: 0,
          two2: 0,
        };

        dCalIrrSingle.forEach((e) => {
          if (e.tr_date == node) {
            obj.data.push(e.data);
            let obj2 = {
              yAxis: e.data,
              xAxis: node,
              reit_code: e.reit_code,
            };
            obj.data2.push(obj2);
          }
        });

        obj.max = Math.max(...obj.data);
        obj.min = Math.min(...obj.data);

        // 2. 计算柱状图颜色区域
        if (obj.min > 0) {
          obj.Y = obj.max - obj.min;
          obj.T = obj.min;
        } else {
          if (obj.max < 0) {
            obj.Y = obj.min;
            obj.T = obj.max;
          } else {
            obj.Y = obj.max;
            obj.T = obj.min;
          }
        }

        // 3. 计算二八位置
        let objlength = Number(obj.max) - Number(obj.min);
        let objTwoeightRatio = objlength * 0.2;

        if (obj.min >= 0) {
          obj.two = Math.abs(objTwoeightRatio / obj.Y);
          obj.two2 = 1 - obj.two;
        } else {
          if (obj.max < 0) {
          } else {
            if (obj.max > objTwoeightRatio) {
              obj.two = Math.abs(objTwoeightRatio / obj.Y);
            } else {
              obj.two = 0;
            }

            if (objTwoeightRatio < Math.abs(obj.min)) {
              obj.eight = Math.abs(objTwoeightRatio / obj.min);
              obj.two2 = 0;
            } else {
              obj.eight = 1;
              obj.two2 = objTwoeightRatio - obj.min;
            }
          }
        }

        DATA.push(obj);
      });
      console.log('initChart9 DATA', DATA);

      // 基座数据
      let DATA2 = DATA.map((node) => {
        return node.T;
      });

      // 蓝色条数据
      let DATA3 = DATA.map((node) => {
        return node.Y;
      });

      //标记点数据
      let DATA4 = DATA.map((node) => {
        return node.data2;
      });

      DATA4 = DATA4.flat();

      DATA4 = DATA4.map((node) => {
        let arr = [];
        arr.push(node.xAxis);
        arr.push(node.yAxis);
        arr.push(node.reit_code);
        return arr;
      });

      let DATA5 = [];
      let DATA6 = [];

      DATE.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };
        DATA4.forEach((e) => {
          if (node == e[0]) {
            let arr = [];
            arr.push(e[1]);
            arr.push(e[2]);
            obj.data.push(arr);
          }
        });

        function fnSort(a, b) {
          return b[0] - a[0];
        }
        obj.data = obj.data.sort(fnSort);

        obj.data.forEach((item) => {
          let arr = [];
          arr.push(node);
          arr.push(item);

          DATA5.push(arr);
        });
      });

      // DATA4 = DATA5;
      DATA6 = DATA5;
      DATA4 = DATA5.map((node) => {
        let arr = [];
        arr.push(node[0]);
        arr.push(node[1][0]);
        return arr;
      });

      // 排序 完美 ！

      let CODENAME = this.$store.state.CODENAME;
      let colors = this.$store.state.colors;
      // console.log("colors", colors);
      let COLOR = colors.find((colornode) => colornode.name == this.dCalIrrSingleColor).value;
      // let COLOR = 'rgba(123 , 122, 177,1)'

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;

      let option = {
        grid: {
          left: 50,
          right: 50,
          top: 50,
          bottom: 55,
          containLabel: true,
        },
        tooltip: {
          confine: true,
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              show: false,
            },
          },
          textStyle: {
            fontSize: 14,
            color: '#666',
          },
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            let str = '';
            str += `<div style="width: 100%;">${args[0].axisValueLabel}</div>`;
            str += `<div style="column-count: 2;">`;
            args.forEach((node) => {
              if (node.seriesType !== 'scatter') return;
              let name = DATA6[node.dataIndex][1][1];
              let val = (Number(node.value[1]) * 100).toFixed(2);
              str += `<div style="display: flex;justify-content: space-between;"><span>${node.marker}${CODENAME[name]}：</span><span>${val}%</span></div>`;
              return str;
            });
            str += `</div>`;
            return str;
          },
        },
        // 标记
        xAxis: {
          type: 'category',
          data: DATE,
          axisLine: {
            show: true,
            onZero: true, //轴线是否在0刻度轴上
            // onZero: true, //轴线是否在0刻度轴上
            lineStyle: {},
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            interval: 10,
            fontSize: 14,
            padding: [0, 0, 0, 0],
            show: true,
            showMaxLabel: true,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          type: 'value',
          interval: 0.1,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,

            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (val) {
              if (val == 0) {
                return 0;
              } else {
                val = Number(val) * 100;
                let data = (val + '').split('.');
                if (data > 0) {
                  if (data < 10) {
                    val = val.toFixed(2);
                  } else {
                    val = val.toFixed(1);
                  }
                } else {
                  if (data > -10) {
                    val = val.toFixed(2);
                  } else {
                    val = val.toFixed(1);
                  }
                }

                val = val + '%';
                return val;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        toolbox: {
          // 工具箱按钮
          right: 20,
          top: 10,
          itemSize: 18,
          iconStyle: {
            borderColor: OPTIONDATA.toolbox.iconStyle.borderColor,
          },
          feature: {
            dataZoom: {}, // 区域缩放
          },
        },

        series: [
          {
            name: 'Placeholder',
            type: 'bar',
            stack: 'Total',
            itemStyle: {
              borderColor: 'transparent',
              color: function (val) {
                let max = DATA[val.dataIndex].max;
                let min = DATA[val.dataIndex].min;

                if (max < 0) {
                  return 'transparent';
                } else {
                  if (min > 0) {
                    return 'transparent';
                  } else {
                    let eight = DATA[val.dataIndex].eight;
                    eight = eight.toFixed(2);

                    let Y = DATA[val.dataIndex].Y;
                    Y = Y.toFixed(2);

                    return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      {
                        offset: 0,
                        color: '#fff',
                      },

                      {
                        offset: eight,
                        color: COLOR,
                      },
                      {
                        offset: 1,
                        color: COLOR,
                      },
                    ]);
                  }
                }
              },
            },

            data: DATA2,
          },

          {
            type: 'scatter', // 指明图表的类型为散点图
            symbol: 'roundRect',
            data: DATA4,
            z: 5,
            itemStyle: {
              color: theme == 'dark' ? '#fff' : '#9e9e9e',
              borderWidth: 1,
            },
            symbolSize: [10, 5],
          },
          {
            type: 'bar',
            stack: 'Total',

            data: DATA3,
            barWidth: '10px',

            itemStyle: {
              borderColor: 'transparent',
              // color: "transparent",
              color: function (val) {
                // return COLOR

                // return COLOR
                let two = DATA[val.dataIndex].two;
                two = two.toFixed(2);

                let two2 = DATA[val.dataIndex].two2;
                two2 = two2.toFixed(2);

                let eight = DATA[val.dataIndex].eight;
                eight = eight.toFixed(2);

                if (DATA[val.dataIndex].min > 0) {
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#fff',
                    },
                    {
                      offset: 0.2,
                      color: COLOR,
                    },
                    {
                      offset: 0.9,
                      color: COLOR,
                    },

                    // {
                    //                           offset: 0.6,
                    //                           color: '#1684fc'
                    //                         },
                    {
                      offset: 1,
                      color: '#fff',
                    }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                  ]);
                } else {
                  if (DATA[val.dataIndex].max < 0) {
                    return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#fff',
                      },
                      {
                        offset: 0.2,
                        color: COLOR,
                      },
                      {
                        offset: 0.8,
                        color: COLOR,
                      },
                      {
                        offset: 1,
                        color: '#fff',
                      }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                    ]);
                  } else {
                    if (DATA[val.dataIndex].two == 0) {
                      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: '#fff',
                        },

                        {
                          offset: 1,
                          color: COLOR,
                        }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                      ]);
                    } else {
                      return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: '#fff',
                        },
                        {
                          offset: two,
                          color: COLOR,
                        },
                        {
                          offset: 1,
                          color: COLOR,
                        }, // { //   offset: 80, //   color: '#4e80ff' // }, // { //   offset: 100, //   color: '#9bb6fb' // }
                      ]);
                    }
                  }
                }
              },
            },
          },
        ],
        dataZoom: {
          // 开启数据平移qc
          type: 'slider', // 滑动条单独显示
          show: true, // 是否显示滑动条
          startValue: DATE[DATE.length - 100], // 展示区域内容的起始数值
          endValue: DATE[DATE.length], // 展示区域内容的结束数值 当前展示x坐标下标为0-16
          showDetail: false, // 拖拽时是否显示详情
          zoomLock: true, // 锁定视图
          brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
        },
      };
      console.log('initChart9 option', option);
      this.chartColumn9.setOption(option);
      this.chartColumn9.resize();
      this.loading_chart9 = false;
    },

    async fnGetCalIrr() {
      this.loading_chart8 = true;
      let r_type = this.category_cascader_val_chart8;
      console.log('fnGetCalIrr r_type', r_type);

      let r_type_list = r_type == '公募REITs' ? [r_type] : r_type;
      console.log('fnGetCalIrr r_type_list', r_type_list);
      let chart8_title = '';
      r_type_list.forEach((node) => {
        if (chart8_title != '') {
          chart8_title += ',';
        }
        chart8_title += node;
      });
      this.chart8_title = chart8_title;
      this.chart9_title = r_type;

      let sel_10yr = this.summaryChecked ? 1 : 0;

      let r_type_str = r_type_list.join('&r_type=');

      const { data } = await this.$https.get(
        '/api/cal_irr_npv?sel_10yr=' +
          sel_10yr +
          '&re=' +
          this.irr_re_list_sel +
          '&cal_mode=' +
          this.irr_call_mode_list_sel +
          '&increase_mode=' +
          this.irr_increase_mode_list_sel +
          '&start_date=' +
          this.start_date_chart8 +
          '&r_type=' +
          r_type_str
      );

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dCalIrr = data.data.by_category;
      this.dCalIrr_down = data.data.by_category;
      this.dCalIrrSingle = data.data.single;
      this.dCalIrrSingleColor = data.data.single_color;

      // 下载 start
      let TMP = Object.keys(this.dCalIrr[0]);
      TMP.forEach((node) => {
        if (node == 'CN10YR') {
          this.json_fields_column8['10年国债Yield Spread'] = node;
        } else if (node == 'yield_spread') {
          this.json_fields_column8['Yield Spread'] = node;
        } else if (node == 'tr_date') {
          this.json_fields_column8['日期'] = node;
        } else {
          this.json_fields_column8[node] = node;
        }
      });

      this.column8Title = this.chart8_title + ' 内含报酬率走势 对比';

      // 下载 end
      this.$nextTick(() => {
        this.initChart8();
      });
    },
    initChart8(val) {
      if (!val) {
        this.chartColumn8 = this.$echarts.init(document.querySelectorAll('#chartColumn8')[0]);
      } else {
        this.chartColumn8 = this.$echarts.init(document.querySelectorAll('#chartColumn8')[1]);
      }
      this.chartColumn8.clear();
      let dCalIrr = this.dCalIrr;
      let colors = this.$store.state.colors;

      let choseType =
        this.category_cascader_val_chart8 == '公募REITs'
          ? [this.category_cascader_val_chart8]
          : this.category_cascader_val_chart8;
      // console.log("initChart8 dCalIrr", choseType, dCalIrr);
      // console.log("initChart8 colors", colors);
      // 除了data、CN10YR、yield_spread的数据
      let newYseries = [];
      choseType.forEach((node) => {
        let color = colors.find((colornode) => colornode.name == node).value;
        let data = {
          name: node,
          data: [],
          type: 'line',
          smooth: false,
          itemStyle: { color: color },
          symbol: 'none',
          label: {
            show: false,
            position: 'top',
          },
        };
        newYseries.push(data);
      });
      newYseries.forEach((serie) => {
        let data = dCalIrr.map((node) => {
          return node[serie.name];
        });
        serie.data = data;
      });
      console.log('initChart8 newYseries', newYseries);

      let X = dCalIrr.map((node) => {
        return node.tr_date;
      });
      // let ARR = Object.keys(dCalIrr[0]);
      // let name = ARR[1];
      let Y2 = [];
      let Y3 = [];
      if (this.summaryChecked) {
        Y2 = dCalIrr.map((node) => {
          return node.CN10YR;
        });
        Y3 = dCalIrr.map((node) => {
          return node.yield_spread;
        });
      }
      let LEGEND = [];
      if (Y2.length > 0) {
        LEGEND.push('10年国债Yield Spread');
      }
      if (Y3.length > 0) {
        LEGEND.push('Yield Spread');
      }

      newYseries.forEach((serie) => {
        LEGEND.push(serie.name);
      });

      // let minYLabel = Math.min(...newYseries[0].data);
      let minYLabel = Math.min(...newYseries.map((item) => Math.min(...item.data)));
      if (this.summaryChecked) {
        console.log('Y2', Y2);
        let minY2 = Math.min(...Y2);
        let minY3 = Math.min(...Y3);
        let minY23 = Math.min(minY2, minY3);
        minYLabel = Math.min(minYLabel, minY23);
      }
      minYLabel = Math.min(minYLabel, 0);
      console.log('initChart8 minYLabel', minYLabel);

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let theme = this.$store.state.theme;
      let lightOpacity = this.$store.state.lightOpacity;
      let option = {
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          formatter: function (args) {
            let str = '';
            str += `${args[0].axisValueLabel}`;
            str += '<br>';
            args.forEach((node) => {
              let val = (Number(node.value) * 100).toFixed(2);
              str += `${node.marker}${node.seriesName}：${val}%`;
              str += '<br>';

              return str;
            });
            return str;
          },
        },
        grid: {
          left: 50,
          top: 90,
          bottom: 35,
          right: 50,
          containLabel: true,
        },
        legend: {
          show: true,
          orient: 'horizontal',
          left: 50,
          top: 35,
          align: 'left',

          icon: 'roundRect',
          itemGap: 30,
          itemHeight: 5,
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: LEGEND,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          splitNumber: 4,
          axisLine: {
            show: true,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            margin: 20,
            color: OPTIONDATA.xAxis.axisLabel.color,
          },

          data: X,
        },
        yAxis: {
          scale: true,
          type: 'value',
          min: minYLabel,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 14,
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              if (args == 0) {
                return '0%';
              } else {
                args = (Number(args) * 100).toFixed(2);
                args = args + '%';
                return args;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            name: '10年国债Yield Spread',
            data: Y2,
            type: 'line',
            smooth: false,
            itemStyle: {
              color: '#2b7eb3',
            },
            symbol: 'none',
            label: {
              show: false,
              position: 'top',
            },
          },
          {
            name: 'Yield Spread',
            data: Y3,
            type: 'line',
            smooth: false,
            itemStyle: {
              color: '#932d13',
            },
            areaStyle: {
              color: '#932d13',
              opacity: theme == 'dark' ? 0.5 : lightOpacity,
            },
            symbol: 'none',

            label: {
              show: false,
              position: 'top',
            },
          },
          ...newYseries,
        ],
      };

      this.chartColumn8.setOption(option);
      this.chartColumn8.resize();
      if (!val) {
        this.fnGetCalIrrSingle();
      }
      this.loading_chart8 = false;
    },

    // chart9
    async fnGetCalIrrSingle() {
      this.loading_chart9 = true;

      let CODELIST = JSON.parse(localStorage.getItem('CODELIST'));
      let NAMES = CODELIST.map((node) => {
        return {
          name: node.reitCode,
          value: node.reitsName,
        };
      });

      this.data_column8 = JSON.parse(JSON.stringify(this.dCalIrrSingle)).map((node) => {
        return {
          tr_date: node.tr_date,
          data: node.data,
          reit_code: NAMES.find((e) => {
            return e.name == node.reit_code;
          }).value,
        };
      });

      this.json_fields_column9 = {
        日期: 'tr_date',
        实际收益率: 'data',
        股票名称: 'reit_code',
      };

      this.column9Title = this.chart9_title + ' 对应个股内含报酬率走势 对比';

      this.$nextTick(() => {
        this.initChart9();
      });
    },

    // chart8
    fnCCascader_select_val_chart8(val) {
      console.log('fnCCascader_select_val_chart8', val);
      if (val.length == 0) {
        alert('必须选择一个类别');
        return;
      }
      if (val.length == 1 && this.irr_call_mode_list_sel == 'IRR') {
        this.disabled_chart8 = false;
      } else {
        this.disabled_chart8 = true;
        this.summaryChecked = false;
      }
      this.category_cascader_val_chart8 = val;
      this.fnGetCalIrr();
    },
    fnButtonDownload1(val) {
      if (val == '下载图片') {
        this.FnDownload7_4();
      } else {
        document.getElementById('downloadExcel7_7').click();
      }
    },
    fnButtonDownload2(val) {
      if (val == '下载图片') {
        this.FnDownload7_4_2();
      } else {
        document.getElementById('downloadExcel7_8').click();
      }
    },
    FnDownload7_4() {
      let h = this.$refs.chartColumn8.scrollHeight;
      let w = this.$refs.chartColumn8.scrollWidth;
      let bgColor = fnDownloadBgc(this.sCurrentTheme);
      console.log('FnDownload7_4', h, w, bgColor);

      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn8, {
        height: h + 20,
        width: w,
        backgroundColor: bgColor,
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '估值财报分析'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },
    FnDownload7_4_2() {
      let h = this.$refs.chartColumn9.scrollHeight;
      let w = this.$refs.chartColumn9.scrollWidth;
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.chartColumn9, {
        height: h + 20,
        width: w,
        backgroundColor: fnDownloadBgc(this.sCurrentTheme),
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = '估值财报分析'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      });
    },

    fnDropdown2(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart9(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart9(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart9(true);
        });
      }
    },

    fnDropdown4(val) {
      if (val == 0) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart8(true);
        });
      } else if (val == 1) {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart8(true);
        });
      } else {
        let conf = this.$store.state.popUpWindowConfi[val];
        this.width_dialog = conf.width_dialog;
        this.top_dialog = conf.top_dialog;
        this.height_dialog = conf.height_dialog;
        this.popUpWindowIndex = val;
        this.height_chart = conf.height_chart;
        this.$nextTick(() => {
          this.initChart8(true);
        });
      }
    },

    fnOpenDialog5() {
      this.dialogVisible5 = true;
      this.$nextTick(() => {
        this.initChart8(true);
      });
    },
    fnOpenDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.initChart9(true);
      });
    }, //  表头方法
    styleBindFun() {
      let val = this.$store.state.theme == 'dark' ? true : false;
      return fnReturnTableStyle(val);
    },
  },
  filters: {
    isZero: function (val) {
      let value = val.substring(0, val.length - 1);
      if (value == 0) {
        return '';
      } else {
        return val;
      }
    },
  },
  components: {
    CSelect,
    CCategoryButton,
    CCascader,
    CButtonsSeparate,
    CTimeButtons,
    CotherButtons,
    CButtonsDownload,
    CDialog,
    summer,
    irr,
  },
  computed: {
    cssVars() {
      return {
        '--ROOTFONTFIZE': this.ROOTFONTFIZE + 'px',
        '--TABPANEHEIGHT': this.TABPANEHEIGHT + 'px',
        '--GMREITs': this.GMREITs,
        '--CQLREITs': this.CQLREITs,
        '--JYQLREITs': this.JYQLREITs,
        '--WLCC': this.WLCC,
        '--CYY': this.CYY,
        '--BZXZF': this.BZXZF,
        '--XF': this.XF,
        '--SZST': this.SZST,
        '--NY': this.NY,
        '--SFGL': this.SFGL,
      };
    },

    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
      this.fnGetTheme();
      fnDownloadBgc();
      this.$nextTick(() => {
        if (this.activeName != 'fifth') {
          return;
        }
        this.initChart8();
        this.initChart9();
      });
      return;
    },
  },
};
</script>

<style scoped>
.openBtn {
  background-color: var(--primary-color) !important;
  color: #333333 !important;
  border: none !important;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.openBtn:hover {
  background-color: var(--primary-color) !important;
  color: #333333 !important;
  border: none !important;
  background-size: contain;
}

.btnArea_center {
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  width: 100%;
}

.container {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  /* line-height: calc(var(--ROOTFONTFIZE) * (20 / 144)); */
  height: 100%;
  /* padding-right: 20px; */
  color: rgba(255, 255, 255, 1);
  /* height: auto; */
}

.top {
  width: 100%;
  height: calc(var(--ROOTFONTFIZE) * (470 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background: rgba(29, 31, 37, 1);
}

.bottom {
  display: flex;
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.bottom .left,
.bottom .right {
  flex: 1;
  height: calc(var(--ROOTFONTFIZE) * (430 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background: rgba(29, 31, 37, 1);
}

.bottom .right {
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.tit {
  margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid rgba(21, 115, 254, 1);
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
}

.other_chart1_style {
  display: flex;
  margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.other_chart1_style div:nth-child(1) {
  flex: 3;
  margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
  display: flex;
  justify-content: center;
}

.other_chart1_style div:nth-child(2) {
  flex: 6;
}

.other_chart1 {
  /* width: 60%; */
}

/* ------------------------------------------------------------------ */
.card {
  /* background-color: rgba(29, 31, 37, 1); */
  background-color: var(--charts_bgc);
  height: auto;
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  /* margin-top: calc(var(--ROOTFONTFIZE) * (5 / 144)); */
  padding-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  overflow: hidden;
}

.card_tit {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  display: flex;
  justify-content: space-between;
}

.card_tit2 {
  display: flex;
  justify-content: end;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.card .main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
  overflow-x: hidden;
  height: 100%;
  position: relative;
}

.hasMore {
}

.hasMore:hover .btnMo,
.hasMore:hover .leftBtn {
  opacity: 1;
  cursor: pointer;
}

.btnMo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 18%;
  width: auto;
  padding: 0 30px;
  display: flex;
  align-items: center;
  opacity: 0;
  background-color: rgba(127, 127, 127, 0.1);
  transition: opacity 0.5s;
}

.OVERVIEWICONSTYLE {
  background-color: #383838;
  color: #f7ecdc;
  font-size: 25px;
  padding: 10px;
  border-radius: 50%;
}

/* .btnMo:hover,
.leftBtn:hover {
  opacity: 1;
  cursor: pointer;
} */

.leftBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0 !important;
  height: 18%;
  width: auto !important;
  padding: 0 30px;
  display: flex;
  align-items: center;
  opacity: 0;
  background-color: rgba(127, 127, 127, 0.1);
  transition: opacity 0.5s;
}

.specialMain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.card .main .main_item {
  width: 30%;
  height: calc(var(--ROOTFONTFIZE) * (300 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
  /* background-color: #1a1c21; */
  background-color: var(--charts_bgc);
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  /* border: 0.2px solid #35373b; */
  border: 1px solid var(--charts_brc);
  /* margin-left: 20px; */
}

.h400 {
  height: 400px !important;
}

.main_item_nothing {
  background-color: transparent !important;
  border: none !important;
}

.card .main .main_item:nth-child(n + 4) {
  height: calc(var(--ROOTFONTFIZE) * (420 / 144));
}

.main_item_first {
  margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-bottom: calc(var(--ROOTFONTFIZE) * (2 / 144)) dotted rgba(118, 118, 118, 0.48);
}

.main_item_second {
  margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.main_item_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 600;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (18.96 / 144));
  color: var(--legndAreaItem_color);
  margin-top: calc(var(--ROOTFONTFIZE) * (15 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (5 / 144));
  padding: 0 calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.main_item_top div span {
  color: var(--GMREITs);
}

.CQL_color {
  color: var(--CQLREITs) !important;
}

.JYQL_color {
  color: var(--JYQLREITs) !important;
}

.WLCH_color {
  color: var(--WLCC) !important;
}

.CYY_color {
  color: var(--CYY) !important;
}

.BZXZF_color {
  color: var(--BZXZF) !important;
}

.XF_color {
  color: var(--XF) !important;
}

.SZST_color {
  color: var(--SZST) !important;
}

.NY_color {
  color: var(--NY) !important;
}

.SFGL_color {
  color: var(--SFGL) !important;
}

.main_item_tit {
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  margin-left: calc(var(--ROOTFONTFIZE) * (10 / 144));
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 600;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (18.96 / 144));
  color: var(--chart_title);
}

.card_top {
  display: flex;
  justify-content: space-between;
  padding: 0 calc(var(--ROOTFONTFIZE) * (25 / 144));
}

.card_top span {
  display: flex;
  align-items: center;
}

.mr20 {
  margin-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.ml20 {
  margin-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.card_bottom {
  padding: calc(var(--ROOTFONTFIZE) * (20 / 144));
  padding-bottom: 0 !important;
}

.card_history_top {
  display: flex;
  padding-left: calc(var(--ROOTFONTFIZE) * (25 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
  align-items: center;
}

.pl {
  padding-left: 0 !important;
}

.card_history_top div {
  color: rgba(255, 255, 255, 1);
}

.valuationCard {
  height: calc(var(--ROOTFONTFIZE) * (920 / 144));
}

.historymain {
  height: calc(var(--ROOTFONTFIZE) * (530 / 144));
  max-height: calc(var(--ROOTFONTFIZE) * (530 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  /* background-color: #1a1c21;
  border: 0.2px solid rgba(135, 135, 135, 0.3); */
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);
  margin: 0 calc(var(--ROOTFONTFIZE) * (25 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (10 / 144));
}

.abslouteMain {
  height: calc(var(--ROOTFONTFIZE) * (365 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #1a1c21;
  border: 0.2px solid rgba(135, 135, 135, 0.3);
  margin: calc(var(--ROOTFONTFIZE) * (38 / 144)) calc(var(--ROOTFONTFIZE) * (45 / 144))
    calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.abslouteMainUp {
  padding-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.abslouteChecked {
  margin: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.abslouteChecked_span {
  color: var(--stock_area_item_color1);
}

.HistoricalValuation {
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.HistoricalValuationMain {
  padding-top: 0px !important;
}

.HistoricalValuation_info {
  padding-left: calc(var(--ROOTFONTFIZE) * (20 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144)) !important;
  padding-bottom: calc(var(--ROOTFONTFIZE) * (50 / 144)) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (12 / 144));
  font-weight: 400;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (17.38 / 144));
  color: var(--stock_area_item_color2);
}

.scatterArea {
  display: flex;
  gap: calc(var(--ROOTFONTFIZE) * (30 / 144));
  padding-top: calc(var(--ROOTFONTFIZE) * (20 / 144)) !important;
}

.scatterArea div:nth-child(1) {
  flex: 3;
  /* margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144)); */
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: var(--charts_bgc);
  border: 0.2px solid var(--charts_brc);
}

.scatterArea div:nth-child(2) {
  flex: 7;
  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: var(--charts_bgc);
  border: 0.2px solid var(--charts_brc);
}

.el-tabs {
  background-color: var(--bigger-card-bgc);
  /* padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144)); */
}

::v-deep .el-tabs__item {
  color: var(--el_tabs_item_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
}

::v-deep .el-tabs__item:hover {
  color: var(--el_tabs_item_color_active_hover) !important;
}

::v-deep .el-tabs__item.is-active {
  color: var(--el_tabs_item_color_active) !important;
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

/*去掉tabs底部的下划线*/
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

/*去掉切换时el-tab-pane底部的蓝色下划线*/
::v-deep .el-tabs__active-bar {
  /* background-color: #1573fe !important; */
  background-color: var(--primary-color) !important;
}

/* el-table start */
::v-deep .table1 table {
  border: none;
}

::v-deep .el-table::before {
  background-color: var(--table_bgc) !important;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
  border-bottom: 1px solid var(--table_brc);
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  color: var(--table_color_hover);
  cursor: pointer;
}

::v-deep .el-table {
  border-bottom: none !important;
  background-color: var(--table_bgc) !important;
  padding-bottom: 90px;
}

::v-deep .el-table__body-wrapper {
  height: auto !important;
  max-height: 100% !important;
  padding-bottom: 10px !important;
}

::v-deep .el-tab-pane {
  display: block;
  width: 100%;
  height: var(--TABPANEHEIGHT);
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.value_area {
  display: flex;
  align-items: center;
  justify-content: start;
}

.a {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #4ec0c5;
}

.b {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #4ec0c5;
}

.c {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #5387be;
}

.d {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #526cbf;
}

.e {
  height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (3 / 144));
  margin-right: calc(var(--ROOTFONTFIZE) * (10 / 144));
  background-color: #5150bf;
}

.canvasArea {
  /* background-color: pink !important; */
}

.historymain2 {
  height: calc(var(--ROOTFONTFIZE) * (620 / 144));
}

.DDLINFO {
  margin-left: calc(var(--ROOTFONTFIZE) * (16 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: rgba(158, 158, 158, 1);
}

.main_item_bottom {
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  text-align: right;
  padding-right: calc(var(--ROOTFONTFIZE) * (50 / 144));
  color: #5ae8ed;
}

.main_item_bottom:hover {
  cursor: pointer;
}

.summary_style {
  display: flex;
  color: var(--stock_area_item_color1);
}

.noVip {
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  letter-spacing: calc(var(--ROOTFONTFIZE) * (2 / 144));
  color: #78787a;
  color: #bababb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--ROOTFONTFIZE) * (500 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: #373a42;
  margin: calc(var(--ROOTFONTFIZE) * (20 / 144)) calc(var(--ROOTFONTFIZE) * (30 / 144));
  border: 1px solid #333335;
}

.display_none {
  display: none;
}

.icon-taocan-suoxiao {
  font-size: calc(var(--ROOTFONTFIZE) * (50 / 144));
  margin-bottom: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.echarts_area {
  position: relative;
  padding: 0 !important;
  overflow: hidden;
}
</style>
