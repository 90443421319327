<template>
  <div class="container" id="rootchart" :style="cssVars">
    <div class="CARD">
      <!-- <div class="CARD_tit">鹏华深圳能源 REITs | 180401.SZ</div> -->
      <div class="CARD_tit">
        {{ STOCKNAME }} | <span class="CARD_tit_code">{{ CODE }}</span>
      </div>

      <div class="CARD_body">
        <CButtons
          @Cbtns="fnGetCbtns"
          class="CARD_body_tabs"
          :dButtons1="dButtons1"
          :defaultVal="dButtons1_current" />

        <div v-show="bButtons1_card1">
          <div class="marketValue">
            <div class="value">
              <span>{{ Number(STOCKPRICE).toFixed(3) }}</span>
              <!-- <span>-2.70%（-1.12%）</span> -->
            </div>
            <div class="time">As of {{ STOCKLASTTIME }} {{ STOCKLASTDATE }}</div>
          </div>

          <div class="chart_area">
            <div class="chart_area_left">
              <div class="canvasArea" ref="chartColumn0" style="width: 100%; height: 500px"></div>
            </div>
            <div class="chart_area_right">
              <CButtons
                @Cbtns="fnCutCbtn"
                class="cutBtn"
                :dButtons1="dButtons2"
                :defaultVal="dButtons2_current" />
              <div
                v-if="dButtons2_current == 'K线'"
                class="canvasArea"
                ref="chartColumn1"
                style="width: 100%; height: 500px"></div>

              <div
                v-else
                class="canvasArea"
                ref="chartColumn1_1"
                style="width: 100%; height: 500px"></div>
            </div>
          </div>

          <div class="CARD_tit CARD_tit2">概览及主要指标</div>
          <div class="CARD_body_table TABLES">
            <table v-if="JSON.stringify(dStockSnapTable) !== '{}'">
              <tr class="rowArea">
                <td>上市日期</td>
                <td class="rowArea_val_color">
                  {{ dStockSnapTable.line1['上市日期'] }}
                </td>
              </tr>

              <tr class="rowArea even" :style="cssVars">
                <td>资产大类</td>
                <td class="rowArea_val_color">
                  {{ dStockSnapTable.line1.资产大类 }}
                </td>
              </tr>

              <tr class="rowArea">
                <td>基础设施类型</td>
                <td class="rowArea_val_color">
                  {{ dStockSnapTable.line1.基础设施类型 }}
                </td>
              </tr>

              <tr class="rowArea even">
                <td>募集规模</td>
                <td class="rowArea_val_color">
                  {{ Number(dStockSnapTable.line1.募集规模 / 100000000).toFixed(2) + ' 亿元' }}
                  <i class="el-icon-question" @click="showStockIpoHistTable"></i>
                </td>
              </tr>
            </table>

            <table v-if="JSON.stringify(dStockSnapTable) !== '{}'">
              <tr class="rowArea secondTable">
                <td>年度价格额范围</td>
                <td :style="cssVars">
                  <div class="middle_table_area">
                    <div class="middle_table_area_left">
                      {{ dStockSnapTable.line2.年度价格范围.min.toFixed(2) }}
                    </div>
                    <div class="middle_table_area_center">
                      <span class="middle_table_area_center_line">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="dStockSnapTable.line2.年度价格范围.last.toFixed(2)"
                          placement="top-start">
                          <span class="pointStyle NDJGFW_position"></span>
                        </el-tooltip>
                      </span>
                    </div>
                    <div class="middle_table_area_right">
                      {{ dStockSnapTable.line2.年度价格范围.max.toFixed(2) }}
                    </div>
                  </div>

                  <!-- <span>{{
                    dStockSnapTable.line2.年度价格范围.min.toFixed(2)
                  }}</span>
                  <span>
                    <span class="pointStyle NDJGFW_position"></span>
                  </span>
                  <span >{{
                    dStockSnapTable.line2.年度价格范围.max.toFixed(2)
                  }}</span> -->
                </td>
              </tr>

              <tr class="rowArea even secondTable">
                <td>年度成交额范围</td>
                <td :style="cssVars">
                  <div class="middle_table_area">
                    <div class="middle_table_area_left">
                      {{
                        (Number(dStockSnapTable.line2.年度成交额范围.min) / 10000).toFixed(1) +
                        '万元'
                      }}
                    </div>
                    <div class="middle_table_area_center">
                      <span class="middle_table_area_center_line">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="
                            (dStockSnapTable.line2.年度成交额范围.last / 10000).toFixed(1) + '万元'
                          "
                          placement="top-start">
                          <span class="pointStyle NDCJEFW_position"></span>
                        </el-tooltip>
                      </span>
                    </div>
                    <div class="middle_table_area_right">
                      {{
                        (Number(dStockSnapTable.line2.年度成交额范围.max) / 10000).toFixed(1) +
                        '万元'
                      }}
                    </div>
                  </div>

                  <!-- <span>{{
                    (
                      Number(dStockSnapTable.line2.年度成交额范围.min) / 10000
                    ).toFixed(1) + " 万元"
                  }}</span>
                  <span>
                    <span class="pointStyle NDCJEFW_position"></span>
                  </span>

                  <span>{{
                    (
                      Number(dStockSnapTable.line2.年度成交额范围.max) / 10000
                    ).toFixed(1) + " 万元"
                  }}</span> -->
                </td>
              </tr>

              <tr class="rowArea secondTable">
                <td>换手率</td>
                <td :style="cssVars">
                  <div class="middle_table_area">
                    <div class="middle_table_area_left">
                      {{ dStockSnapTable.line2.换手率范围.min.toFixed(2) + '%' }}
                    </div>
                    <div class="middle_table_area_center">
                      <span class="middle_table_area_center_line">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="dStockSnapTable.line2.换手率范围.last.toFixed(2) + '%'"
                          placement="top-start">
                          <span class="pointStyle HSL_position"></span>
                        </el-tooltip>
                      </span>
                    </div>
                    <div class="middle_table_area_right">
                      {{ dStockSnapTable.line2.换手率范围.max.toFixed(2) + '%' }}
                    </div>
                  </div>

                  <!-- <span>{{
                    dStockSnapTable.line2.换手率范围.min.toFixed(2) + "%"
                  }}</span>
                  <span>
                    <span class="pointStyle HSL_position"></span>
                  </span>
                  <span>{{
                    dStockSnapTable.line2.换手率范围.max.toFixed(2) + "%"
                  }}</span> -->
                </td>
              </tr>

              <tr class="rowArea even secondTable">
                <td>市值范围</td>
                <td :style="cssVars">
                  <div class="middle_table_area">
                    <div class="middle_table_area_left">
                      {{ (dStockSnapTable.line2.市值范围.min / 100000000).toFixed(1) + '亿元' }}
                    </div>
                    <div class="middle_table_area_center">
                      <span class="middle_table_area_center_line">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="
                            (dStockSnapTable.line2.市值范围.last / 100000000).toFixed(1) + '亿元'
                          "
                          placement="top-start">
                          <span class="pointStyle SZFW_position"></span>
                        </el-tooltip>
                      </span>
                    </div>
                    <div class="middle_table_area_right">
                      {{ (dStockSnapTable.line2.市值范围.max / 100000000).toFixed(1) + '亿元' }}
                    </div>
                  </div>

                  <!-- <span>{{
                    (dStockSnapTable.line2.市值范围.min / 100000000).toFixed(
                      1
                    ) + "亿元"
                  }}</span>
                  <span>
                    <span class="pointStyle SZFW_position"></span>
                  </span>
                  <span>{{
                    (dStockSnapTable.line2.市值范围.max / 100000000).toFixed(
                      1
                    ) + "亿元"
                  }}</span> -->
                </td>
              </tr>
            </table>

            <table v-if="JSON.stringify(dStockSnapTable) !== '{}'">
              <tr class="rowArea thirdTable">
                <td>P/NAV</td>
                <td :style="cssVars">
                  <div>
                    {{
                      dStockSnapTable.line3['P/NAV'].min
                        ? dStockSnapTable.line3['P/NAV'].min.toFixed(1) + 'X'
                        : ''
                    }}
                  </div>
                  <div>
                    <span class="fLINESTYLE2">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="
                          dStockSnapTable.line3['P/NAV'].last
                            ? dStockSnapTable.line3['P/NAV'].last.toFixed(2) + 'X'
                            : ''
                        "
                        placement="top-start">
                        <span class="pointStyle PNAV_position"></span>
                      </el-tooltip>
                    </span>
                  </div>
                  <div class="LINEVALUE">
                    {{
                      dStockSnapTable.line3['P/NAV'].max
                        ? dStockSnapTable.line3['P/NAV'].max.toFixed(1) + 'X'
                        : ''
                    }}
                  </div>
                  <!-- <span> 
                    <span></span>
                    <span class="pointStyle PNAV_position"></span>
                  </span>
                  <span>{{
                    dStockSnapTable.line3["P/NAV"].max.toFixed(1) + "X"
                  }}</span> -->
                </td>
              </tr>

              <tr class="rowArea even thirdTable">
                <td>PFFO</td>
                <td :style="cssVars">
                  <div>
                    {{
                      dStockSnapTable.line3['PFFO'].min
                        ? dStockSnapTable.line3['PFFO'].min.toFixed(1) + 'X'
                        : ''
                    }}
                  </div>
                  <div>
                    <span class="fLINESTYLE2">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="
                          dStockSnapTable.line3['PFFO'].last
                            ? dStockSnapTable.line3['PFFO'].last.toFixed(1) + 'X'
                            : ''
                        "
                        placement="top-start">
                        <span class="pointStyle PFFO_position"></span>
                      </el-tooltip>
                    </span>
                  </div>
                  <div class="LINEVALUE">
                    {{
                      dStockSnapTable.line3['PFFO'].max
                        ? dStockSnapTable.line3['PFFO'].max.toFixed(1) + 'X'
                        : ''
                    }}
                  </div>

                  <!-- <span>
                    <span></span>
                    <span class="pointStyle PFFO_position"></span>
                  </span>
                  <span>{{
                    dStockSnapTable.line3.PFFO.max.toFixed(1) + "X"
                  }}</span> -->
                </td>
              </tr>

              <tr class="rowArea thirdTable">
                <td>PCFO</td>
                <td :style="cssVars">
                  <div>
                    {{
                      dStockSnapTable.line3['PCFO'].min
                        ? dStockSnapTable.line3['PCFO'].min.toFixed(1) + 'X'
                        : ''
                    }}
                  </div>
                  <div>
                    <span class="fLINESTYLE2">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="
                          dStockSnapTable.line3['PCFO'].last
                            ? dStockSnapTable.line3['PCFO'].last.toFixed(1) + 'X'
                            : ''
                        "
                        placement="top-start">
                        <span class="pointStyle PCFO_position"></span>
                      </el-tooltip>
                    </span>
                  </div>
                  <div class="LINEVALUE">
                    {{
                      dStockSnapTable.line3['PCFO'].max
                        ? dStockSnapTable.line3['PCFO'].max.toFixed(1) + 'X'
                        : ''
                    }}
                  </div>

                  <!-- <span>
                    <span></span>
                    <span class="pointStyle PCFO_position"></span>
                  </span>
                  <span>{{
                    dStockSnapTable.line3.PCFO.max.toFixed(1) + "X"
                  }}</span> -->
                </td>
              </tr>

              <tr class="rowArea even">
                <td>分红回报率</td>
                <td>
                  <span>{{ FLHBL }}</span>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="CARD" v-show="bButtons1_card2">
          <div class="CARD_body_table TABLES TABLES_borderbottom_none">
            <el-table
              class="table1"
              :header-cell-style="styleBindFun"
              :data="dAssetValueByCode"
              style="width: 100%"
              :height="dAssetValueByCode.length * 60 + 75">
              <el-table-column prop="index" label="No." align="center"> </el-table-column>
              <el-table-column prop="资产名称" label="资产名称" align="center" width="300">
              </el-table-column>
              <el-table-column align="center" prop="资产类型" label="资产类型"> </el-table-column>
              <el-table-column align="center" prop="所在省份" label="所在省份"> </el-table-column>

              <el-table-column align="center" prop="所在城市" label="所在城市"> </el-table-column>
              <el-table-column align="center" prop="用地性质" label="用地性质"> </el-table-column>
              <el-table-column align="center" prop="上市时间" label="上市时间"> </el-table-column>
              <el-table-column align="center" prop="运营到期时间" label="运营到期时间">
              </el-table-column>
              <el-table-column
                align="center"
                width="200"
                prop="最新估值（亿元）"
                label="最新估值（亿元）">
              </el-table-column>
              <!-- <el-table-column align="center" prop="lat" label="lat">
              </el-table-column>
              <el-table-column align="center" prop="lon" label="lon">
              </el-table-column> -->
            </el-table>
          </div>

          <div class="CARD_body_echart HEIGHT_500">
            <div class="canvasArea" ref="chartColumn3" style="width: 100%; height: 450px"></div>
          </div>
        </div>

        <div class="CARD" v-show="bButtons1_card3">
          <el-carousel :interval="10000" height="600px" indicator-position="none">
            <el-carousel-item class="carousel_item" v-for="(item, idx) in structureImgs" :key="idx">
              <h3 class="medium">
                <div class="name">{{ idx + 1 }} / {{ structureImgs.length }}</div>
                <el-image
                  style="width: 60%; height: 30%"
                  :src="item.src"
                  :preview-src-list="structureImgs2"
                  fit="fill">
                </el-image>
                <div class="name">
                  {{ item.name }}
                </div>
              </h3>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="CARD" v-show="bButtons1_card4">
          <div class="RelatedInstitutions">
            <div
              class="RelatedInstitutions_item"
              v-for="(node, index) in dOperationInfo"
              :key="index"
              :class="index % 2 == 0 ? 'odd' : ''">
              <div class="RelatedInstitutions_item_left">{{ node[0] }}</div>
              <div class="RelatedInstitutions_item_right">{{ node[1] }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <stockIpoHistTable v-bind.sync="show_stock_ipo_hist_table"/>
  </div>
</template>

<script>
import CSelects from '@/components/Basic/CSelects';
import CCategoryRadio from '@/components/Basic/CCategoryRadio';
import CButtons from '@/components/Basic/CButtons';
import stockIpoHistTable from '@/components/dialog/stock_ipo_hist_table';

import { fnReturnTableStyle, fnGetMuchDecimals } from '@/utils/util';

export default {
  data() {
    return {
      show_stock_ipo_hist_table:{
        show:false,
        code:"",
      },

      ROOTFONTFIZE: 0,
      CODE: window.sessionStorage.getItem('CURRENTCODE'),
      STOCKPRICE: null,
      STOCKNAME: window.sessionStorage.getItem('CURRENTNAME'),
      STOCKLASTTIME: null,
      STOCKLASTDATE: null,

      show: false,

      MAP_L1: {
        '180101.SZ': '产权类',
        '508000.SH': '产权类',
        '508027.SH': '产权类',
        '508099.SH': '产权类',
        '180102.SZ': '产权类',
        '508021.SH': '产权类',
        '508088.SH': '产权类',
        '180103.SZ': '产权类',
        '508019.SH': '产权类',
        '180501.SZ': '产权类',
        '508058.SH': '产权类',
        '508068.SH': '产权类',
        '508077.SH': '产权类',
        '180301.SZ': '产权类',
        '508056.SH': '产权类',
        '508098.SH': '产权类',
        '180801.SZ': '经营权类',
        '508006.SH': '经营权类',
        '180201.SZ': '经营权类',
        '508001.SH': '经营权类',
        '180202.SZ': '经营权类',
        '508018.SH': '经营权类',
        '508008.SH': '经营权类',
        '508066.SH': '经营权类',
        '508009.SH': '经营权类',
        '180401.SZ': '经营权类',
        '508028.SH': '经营权类',
        '508096.SH': '经营权类',
        '508007.SH': '经营权类',
      },
      MAP_L2: {
        '180101.SZ': '产业园',
        '508000.SH': '产业园',
        '508027.SH': '产业园',
        '508099.SH': '产业园',
        '180102.SZ': '产业园',
        '508021.SH': '产业园',
        '508088.SH': '产业园',
        '180103.SZ': '产业园',
        '508019.SH': '产业园',
        '180501.SZ': '保障性住房',
        '508058.SH': '保障性住房',
        '508068.SH': '保障性住房',
        '508077.SH': '保障性住房',
        '180301.SZ': '物流仓储',
        '508056.SH': '物流仓储',
        '508098.SH': '物流仓储',
        '180801.SZ': '市政生态',
        '508006.SH': '市政生态',
        '180201.SZ': '收费公路',
        '508001.SH': '收费公路',
        '180202.SZ': '收费公路',
        '508018.SH': '收费公路',
        '508008.SH': '收费公路',
        '508066.SH': '收费公路',
        '508009.SH': '收费公路',
        '180401.SZ': '能源',
        '508028.SH': '能源',
        '508096.SH': '能源',
        '508007.SH': '收费公路',
      },
      basis_value: '',
      basis_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      days_value: '',
      days_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      level_value: '',
      level_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      classA_value: '',
      classA_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      classB_value: '',
      classB_options: [
        {
          name: '下拉菜单',
          value: '0',
        },
      ],

      radio1: '',
      radio2: '',

      dBtn1: ['3M', '6M', '1Y', 'YTD', 'MAX'],
      activeBtn1: '3M',

      //================ new =================================
      dButtons1: ['概览', '底层资产', '交易结构', '相关机构'],
      dButtons1_current: '概览',

      dButtons2: ['K线', '市值'],
      dButtons2_current: 'K线',
      marketCap: [],

      bButtons1_card1: true,
      bButtons1_card2: false,
      bButtons1_card3: false,
      bButtons1_card4: false,

      chartColumn0: null,
      chart0_DATA: null,
      chartColumn1: null,
      chartColumn1_1: null,
      chart1_DATA: null,

      // 表格1
      dStockSnapTable: {},
      NDJGFW: '',
      NDCJEFW: '',
      HSL: '',
      SZFW: '',

      PNAV: '',
      PFFO: '',
      PCFO: '',
      FLHBL: '',

      // 表格2
      dAssetValueByCode: [],

      // 底层资产估值变化（亿元）数据
      dSingleAssetArea: [],
      chartColumn3: null,
      chart3_data: null,

      // 相关机构 数据
      dOperationInfo: [],

      colorqc: '#f46900',

      // 交易结构 start
      structureImgs: [],
      structureImgs2: [],

      // 交易结构 end
    };
  },
  created() {
    this.fnGetCbtns('概览');
  },
  mounted() {
    this.fnGetWidth();
    window.addEventListener('resize', this.funResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.funResize);
    this.chartColumn0 && this.chartColumn0.dispose();
    this.chartColumn1 && this.chartColumn1.dispose();
    this.chartColumn1_1 && this.chartColumn1_1.dispose();
    this.chartColumn3 && this.chartColumn3.dispose();
  },
  computed: {
    cssVars() {
      return {
        '--colorqc': this.colorqc,
        '--NDJGFW': this.NDJGFW,
        '--NDCJEFW': this.NDCJEFW,
        '--HSL': this.HSL,
        '--SZFW': this.SZFW,

        '--PNAV': this.PNAV,
        '--PFFO': this.PFFO,
        '--PCFO': this.PCFO,
      };
    },
    currentTheme() {
      return this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      this.styleBindFun();
      this.fnGetDealStructure();

      // this.$nextTick(()=>{
      //    this.initChart0()
      //    this.initChart1()
      //    this.initChart3_1()
      // })

      this.$nextTick(() => {
        this.initChart0();
      });

      this.$nextTick(() => {
        this.initChart1();
      });

      this.$nextTick(() => {
        this.initChart1_1();
      });

      this.$nextTick(() => {
        this.initChart3_1();
      });

      return;
      this.initChart();
      this.initChart2();
      this.initChart3();
      this.initChart4();
      this.initChart5();
    },
  },

  methods: {
    showStockIpoHistTable(){
      this.show_stock_ipo_hist_table = {
        show:true,
        code:this.CODE
      };
    },
    funResize() {
      this.fnGetWidth();
      if (this.chartColumn0) {
        // this.chartColumn0.resize();
        this.initChart0();
      }
      if (this.chartColumn1) {
        this.chartColumn1.resize();
      }
      if (this.chartColumn3) {
        this.chartColumn3.resize();
      }
    },

    fnGetWidth() {
      this.ROOTFONTFIZE = localStorage.getItem('ROOTFONTFIZE');
    },

    // 1. 获取折线图
    async fnGetStockMinsData() {
      let code = this.CODE;
      // const { data } = await this.$https.get(
      //   "/api/stock_mins_data?code=" + code
      // );

      const { data } = await this.$https.get('/api/stock_mins_data?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);

      let DATA = data.data;

      // 标题
      console.log(this.STOCKPRICE, 'this.STOCKPRICE');
      this.$nextTick(() => {
        this.STOCKPRICE = DATA[DATA.length - 1].close;
      });
      console.log(this.STOCKPRICE, 'this.STOCKPRICE2执行了撒大苏打实打实的');

      let ARR = DATA[DATA.length - 1].tr_date.split(' ');
      this.STOCKLASTTIME = ARR[1].slice(0, -3);
      this.STOCKLASTDATE = ARR[0];

      this.chart0_DATA = DATA;
      this.$nextTick(() => {
        this.initChart0();
      });
    },

    // 2. 获取K线图
    async fnGetStockCandle() {
      let code = this.CODE;

      const { data } = await this.$https.get('/api/stock_candle?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);

      let DATA = data.data;
      this.chart1_DATA = DATA;
      this.$nextTick(() => {
        this.initChart1();
      });
    },

    initChart0() {
      this.chartColumn0 = this.$echarts.init(this.$refs.chartColumn0);

      let DATA = this.chart0_DATA;

      let X = DATA.map((node) => {
        return node.tr_date;
      });

      let Y_LINE = DATA.map((node) => {
        return node.close;
      });

      let Y_BAR = DATA.map((node) => {
        return node.vol;
      });

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        tooltip: {
          trigger: 'axis',
          triggerOn: 'mousemove|click',
          backgroundColor: 'rgba(255,255,255,0.9)',
          textStyle: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            color: '#666',
            fontWeight: 'normal',
          },
        },
        xAxis: [
          {
            type: 'category',
            data: X,
            boundaryGap: true,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            axisLabel: {
              margin: this.ROOTFONTFIZE * (10 / 144),
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              padding: [0, 0, 0, 0],
              show: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
            min: 'dataMin',
            max: 'dataMax',
            axisPointer: {
              show: true,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
          {
            type: 'category',
            boundaryGap: true,
            gridIndex: 1,
            data: X,
            splitLine: {
              show: false,
            },
            axisLabel: {
              margin: this.ROOTFONTFIZE * (10 / 144),
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              padding: [0, 0, 0, 0],
              show: false,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              onZero: false, //轴线是否在0刻度轴上
              // onZero: true, //轴线是否在0刻度轴上
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
            // min: "dataMin",
            // max: "dataMax",
          },
        ],
        yAxis: [
          {
            scale: true,
            // splitNumber: 2,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#767676',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  let num = (parseInt(Math.abs(args)) + '').length >= 2 ? 2 : 3;
                  return Number(args).toFixed(num);
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        grid: [
          {
            left: this.ROOTFONTFIZE * (70 / 144),
            right: this.ROOTFONTFIZE * (60 / 144),
            top: this.ROOTFONTFIZE * (50 / 144),
            bottom: this.ROOTFONTFIZE * (180 / 144),
            // height: ,
          },
          {
            left: this.ROOTFONTFIZE * (80 / 144),
            right: this.ROOTFONTFIZE * (60 / 144),
            top: this.ROOTFONTFIZE * (360 / 144),
            bottom: this.ROOTFONTFIZE * (60 / 144),
          },
        ],

        dataZoom: [
          //用于区域缩放
          {
            type: 'inside',
            xAxisIndex: [0, 1],
            start: 0,
            end: 100,
          },
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '92%',
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            name: '成交量',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: '#8d8e90',
              },
              emphasis: {
                color: '#8d8e90',
              },
            },
            data: Y_BAR,
          },
          {
            name: '分钟线',
            type: 'line',
            data: Y_LINE,
            itemStyle: {
              normal: {
                color: '#ef232a',
              },
            },
            smooth: true,
            symbol: 'none',
          },
        ],
      };

      this.chartColumn0.setOption(option);
      this.chartColumn0.resize();
      // this.funResize()
    },

    initChart1() {
      this.chartColumn1 = this.$echarts.init(this.$refs.chartColumn1);
      this.chartColumn1.clear();

      let DATA = this.chart1_DATA;

      let X = DATA.map((node) => {
        return node.tr_date;
      });

      let Y = DATA.map((node) => {
        let data = [];
        data.push(node.open);
        data.push(node['close_bfq']);
        data.push(node['low']);
        data.push(node['high']);

        return data;
      });

      let Y_BAR = DATA.map((node) => {
        return node.vol;
      });

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        tooltip: {
          backgroundColor: 'rgba(255,255,255,0.9)',
          textStyle: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            color: '#666',
          },
          formatter: function (params) {
            if (params[0]) {
              let color = params[0].color;
              let marker = `<span style="display:inline-block;margin-right:4px;border-radius:5px;width:6px;height:6px;line-height: 6px;background-color:${color};"></span>`;

              var res = params[0].name + '<br/>';
              res += `${params[0].marker} ${params[0].seriesName} <br/>`;
              res += `${marker}` + ' 开盘 : ' + params[0].value[1] + '<br/>';
              res += `${marker}` + '  最高 : ' + params[0].value[3] + '<br/>';
              res += `${marker}` + '  收盘 : ' + params[0].value[2] + '<br/>';
              res += `${marker}` + '  最低 : ' + params[0].value[4];
              return res;
            } else {
              return `${params.seriesName}:${params.value}`;
            }
          },
        },
        xAxis: [
          {
            type: 'category',
            data: X,
            boundaryGap: true,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            axisLabel: {
              margin: this.ROOTFONTFIZE * (10 / 144),
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              padding: [0, 0, 0, 0],
              show: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
            min: 'dataMin',
            max: 'dataMax',
            axisPointer: {
              show: true,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
          },
          {
            type: 'category',
            boundaryGap: true,
            gridIndex: 1,
            data: X,
            splitLine: {
              show: false,
            },
            axisLabel: {
              margin: this.ROOTFONTFIZE * (10 / 144),
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              padding: [0, 0, 0, 0],
              show: false,
              showMaxLabel: true,
              color: OPTIONDATA.xAxis.axisLabel.color,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              onZero: false, //轴线是否在0刻度轴上
              // onZero: true, //轴线是否在0刻度轴上
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: OPTIONDATA.splitLine.lineStyle.color,
              },
            },
            // min: "dataMin",
            // max: "dataMax",
          },
        ],
        yAxis: [
          {
            scale: true,
            // splitNumber: 2,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#767676',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              fontSize: this.ROOTFONTFIZE * (14 / 144),
              show: true,
              color: OPTIONDATA.yAxis.axisLabel.color,
              formatter: function (args) {
                if (args == 0) {
                  return 0;
                } else {
                  let num = (parseInt(Math.abs(args)) + '').length >= 2 ? 1 : 2;
                  return Number(args).toFixed(num);
                }
              },
            },
            splitLine: {
              show: false,
            },
          },
          {
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        grid: [
          {
            left: this.ROOTFONTFIZE * (70 / 144),
            right: this.ROOTFONTFIZE * (60 / 144),
            top: this.ROOTFONTFIZE * (70 / 144),
            bottom: this.ROOTFONTFIZE * (180 / 144),
            // height: ,
          },
          {
            left: this.ROOTFONTFIZE * (50 / 144),
            right: this.ROOTFONTFIZE * (60 / 144),
            top: this.ROOTFONTFIZE * (360 / 144),
            bottom: this.ROOTFONTFIZE * (60 / 144),
          },
        ],

        dataZoom: [
          //用于区域缩放
          {
            type: 'inside',
            xAxisIndex: [0, 1],
            start: 0,
            end: 100,
          },
          {
            show: true,
            xAxisIndex: [0, 1],
            type: 'slider',
            top: '92%',
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            name: '成交额',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color: '#8d8e90',
              },
              emphasis: {
                color: '#8d8e90',
              },
            },
            data: Y_BAR,
          },
          {
            type: 'candlestick',
            name: 'K线',
            data: Y,
            itemStyle: {
              normal: {
                color: '#ef232a',
                color0: '#28a745',
                borderColor: '#ef232a',
                borderColor0: '#28a745',
              },
            },
          },
        ],
      };
      this.chartColumn1.setOption(option);
      this.chartColumn1.resize();
      // this.funResize()
    },

    fnCutCbtn(val) {
      this.dButtons2_current = val;
      if (this.dButtons2_current == '市值') {
        this.$nextTick(() => {
          this.initChart1_1();
        });
      } else {
        this.$nextTick(() => {
          this.initChart1();
        });
      }
    },

    async fnGetStockMvCharts() {
      let code = this.CODE;
      const { data } = await this.$https.get('/api/stock_mv_charts?code=' + code);
      if (data.code !== 200) return this.$message.error(data.msg);
      this.marketCap = data.data;
      this.initChart1_1();
    },

    initChart1_1() {
      this.chartColumn1_1 = this.$echarts.init(this.$refs.chartColumn1_1);
      this.chartColumn1_1.clear();

      let DATA = this.marketCap;

      // x轴
      let X = DATA.map((node) => {
        return node['tr_date'];
      });

      let name = Object.keys(DATA[0]);
      name.shift();
      // 总市值
      // 流通市值
      let serriesArr = [];
      name.forEach((node) => {
        let obj = {
          name: node,
          value: [],
        };

        obj.value = DATA.map((item) => {
          return item[node];
        });
        serriesArr.push(obj);
      });

      name = ['总市值', '流通市值'];
      serriesArr[0].name = '总市值';
      serriesArr[1].name = '流通市值';

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let option = {
        grid: {
          top: this.ROOTFONTFIZE * (120 / 144),
          bottom: this.ROOTFONTFIZE * (20 / 144),
          left: this.ROOTFONTFIZE * (30 / 144),
          right: this.ROOTFONTFIZE * (50 / 144),
          containLabel: true,
        },
        legend: {
          show: true,
          orient: 'horizontal',
          // left:'right',
          left: this.ROOTFONTFIZE * (20 / 144),
          align: 'left',
          top: this.ROOTFONTFIZE * (60 / 144),
          // x:50,
          // x2: 150,
          icon: 'roundRect',
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemWidth: this.ROOTFONTFIZE * (15 / 144),
          itemHeight: this.ROOTFONTFIZE * (15 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: name,
        },
        tooltip: {
          // 工具提示
          // trigger:'item',
          trigger: 'axis', // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
          triggerOn: 'mousemove|click', // 触发时机, click代表点击, mouseOver代表鼠标移过
          formatter: function (args) {
            let str = '';
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let val = node.value / 100000000;

              val = Number(val).toFixed(fnGetMuchDecimals(args));
              // str += "<br>";
              str += `<div style="display:flex;justify-content:space-between;"><span> ${
                node.marker
              }${node.seriesName}：</span><span>${Number(val) !== 0 ? val : 0}${
                Number(val) !== 0 ? '亿元' : ''
              }</span></div>`;

              return str;
            });
            return str;
          },
        },
        xAxis: {
          type: 'category',
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            // interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: this.ROOTFONTFIZE * (30 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          name: '单位：(亿元)',
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          type: 'value',
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              args = Number(args) / 100000000;
              // let num = (parseInt(Math.abs(args)) + "").length >= 2 ? 1 : 2;
              args = args.toFixed(fnGetMuchDecimals(args));
              return args;
            },
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: [
          {
            type: 'line',
            name: serriesArr[0].name,
            data: serriesArr[0].value,
            symbol: 'none',
            itemStyle: {
              color: '#54aeab',
              // color: function (val) {
              //   console.log(val,'迈凯凯轮');
              //   if(val.seriesName == '总市值'){
              //     return '#54aeab'
              //   }else{
              //     return '#514faf'
              //   }
              // },
            },
            areaStyle: {
              color: '#54aeab',
            },
          },

          {
            type: 'line',
            name: serriesArr[1].name,
            data: serriesArr[1].value,
            symbol: 'none',
            itemStyle: {
              color: '#514faf',
            },
            areaStyle: {
              color: '#514faf',
            },
          },
        ],
        // series: [
        //   {
        //     // type: "line",
        //     // name: Y1.name,
        //     // data: Y1.data,
        //     // symbol: "none",
        //     // stack: "all",
        //     // itemStyle: {
        //     //   color: "rgba(90, 202, 198, 1)",
        //     // },
        //     // areaStyle: {
        //     //   color: "rgba(90, 202, 198, 1)",
        //     // },
        //   },
        //   {
        //     type: "line",
        //     stack: "all",
        //     symbol: "none",
        //     name: Y2.name,
        //     data: Y2.data,
        //     itemStyle: {
        //       color: "rgba(89, 143, 200, 1)",
        //     },
        //     areaStyle: {
        //       color: "rgba(89, 143, 200, 1)",
        //     },
        //   },
        // {

        // },
        // ],
      };

      this.chartColumn1_1.setOption(option);
      this.chartColumn1_1.resize();
      // this.funResize()
    },

    // =====================================================================================================
    //  表头方法
    styleBindFun() {
      let val = this.$store.state.theme == 'dark' ? true : false;
      return fnReturnTableStyle(val);
    },

    // 1. 获取表格1数据
    async fnGetStockSnapTable() {
      let code = this.CODE;

      const { data } = await this.$https.get('/api/stock_snap_table?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dStockSnapTable = data.data;

      console.log(this.dStockSnapTable, '刘天仙');

      // line2
      // 1. 年度价格范围
      let obj = data.data.line2.年度价格范围;
      let val1 = Number(obj.max) - Number(obj.min);
      let NDJGFW = (Number(obj.last) - Number(obj.min)) / val1;
      NDJGFW = NDJGFW * 100;
      NDJGFW = NDJGFW.toFixed(2) + '%';
      this.NDJGFW = NDJGFW;

      // 2. 年度成交额范围
      let obj2 = data.data.line2.年度成交额范围;
      let val2 = Number(obj2.max) - Number(obj2.min);
      let NDCJEFW = (Number(obj2.last) - Number(obj2.min)) / val2;

      NDCJEFW = NDCJEFW * 100;
      NDCJEFW = NDCJEFW.toFixed(2) + '%';
      this.NDCJEFW = NDCJEFW;

      // 3. 换手率范围
      let obj3 = data.data.line2.换手率范围;
      let val3 = Number(obj3.max) - Number(obj3.min);
      let HSL = (Number(obj3.last) - Number(obj3.min)) / val3;
      HSL = HSL * 100;
      HSL = HSL.toFixed(2) + '%';
      this.HSL = HSL;

      // 4. 市值范围
      let obj4 = data.data.line2.市值范围;
      let val4 = Number(obj4.max) - Number(obj4.min);
      let SZFW = (Number(obj4.last) - Number(obj4.min)) / val4;
      SZFW = SZFW * 100;
      SZFW = SZFW.toFixed(2) + '%';
      this.SZFW = SZFW;

      // 测试完  正确

      // line3
      // 1. P/NAV
      let obj5 = data.data.line3['P/NAV'];
      if (obj5) {
        let val5 = Number(obj5.max) - Number(obj5.min);
        let PNAV = (Number(obj5.last) - Number(obj5.min)) / val5;
        PNAV = PNAV * 100;
        PNAV = PNAV.toFixed(2) + '%';
        this.PNAV = PNAV;
      } else {
        this.PNAV = '0%';
      }

      // 2. PFFO
      let obj6 = data.data.line3['PFFO'];
      if (obj6) {
        let val6 = Number(obj6.max) - Number(obj6.min);
        let PFFO = (Number(obj6.last) - Number(obj6.min)) / val6;
        PFFO = PFFO * 100;
        PFFO = PFFO.toFixed(2) + '%';
        this.PFFO = PFFO;
      } else {
        this.PFFO = '0%';
      }

      // 3. PCFO
      let obj7 = data.data.line3['PCFO'] ? data.data.line3['PCFO'] : 0;
      if (obj7) {
        let val7 = Number(obj7.max) - Number(obj7.min);
        let PCFO = (Number(obj7.last) - Number(obj7.min)) / val7;
        PCFO = PCFO * 100;
        PCFO = PCFO.toFixed(2) + '%';
        this.PCFO = PCFO;
      } else {
        this.PCFO = '0%';
      }

      // 4. 分红回报率
      let obj8 = data.data.line3['分红回报率'];
      // let val8 = Number(obj8.max) - Number(obj8.min);
      // let FLHBL = (Number(obj8.last) - Number(obj8.min)) / val8;
      // FLHBL = FLHBL * 100;
      // FLHBL = FLHBL.toFixed(1) + "%";

      let num = '';
      if (Number(obj8.last) * 100 > -10 && Number(obj8.last) * 100 < 10) {
        num = 2;
      } else {
        num = 1;
      }

      let FHHBL = (Number(obj8.last) * 100).toFixed(num) + '%';
      this.FLHBL = FHHBL;

      //  完美！ 漂亮
    },

    // 2. 获取表格2数据
    async fnGetAssetValueByCode() {
      let code = this.CODE;

      const { data } = await this.$https.get('/api/asset_value_by_code?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dAssetValueByCode = data.data;

      // this.
      this.dAssetValueByCode.map((node) => {
        let val = node['最新估值（亿元）'] / 10000;
        let num = (parseInt(Math.abs(val)) + '').length >= 2 ? 1 : 2;
        node['最新估值（亿元）'] = val.toFixed(num);
      });
    },

    // 3. 获取 底层资产估值变化（亿元）数据
    async fnGetSingleAssetArea() {
      return;
      let code = '508000.SH';

      const { data } = await this.$https.get('/api/single_asset_area?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);

      this.dSingleAssetArea = data.data;
      this.initChart3();
    },
    initChart3() {
      return;
      this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);

      let dSingleAssetArea = this.dSingleAssetArea;
      let data1 = dSingleAssetArea.area_plot;
      let X = data1.map((node) => {
        return node.rp_date;
      });

      let arr_Y = Object.keys(data1[0]);
      arr_Y.shift();
      let Y = [];
      arr_Y.forEach((node) => {
        let obj = {
          name: '',
          data: [],
        };

        obj.name = node;
        data1.forEach((e) => {
          obj.data.push(e[node]);
        });

        Y.push(obj);
      });

      // ipo
      // let arr1 = dValuationAnalysisSingle_IPO.map(node=>{
      //   return node.IPO_date
      // })

      // arr1 = [...new Set(arr1)]

      // let markPointData = arr1.map(node=>{
      //   let obj ={
      //      yAxis: 0,
      //      xAxis: node,
      //   }
      //   return obj
      // })

      // let dValuationAnalysisSingle_pic1_lines=this.dValuationAnalysisSingle_pic1_lines

      let option = {
        grid: {
          top: 70,
          bottom: 60,
          right: 50,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          // 工具提示
          trigger: 'axis',
          triggerOn: 'click', // 触发时机, click代表点击, mouseOver代表鼠标移过
          // formatter: function (args) {
          //   // 文字格式化
          //   return args[0].name + "的市值是：" + args[0].data;
          // },
        },

        // colors:['#5dccc8','#5654ca','#d43030'],
        // legend: [
        //       {
        //     show: true,
        //     itemWidth: 15,
        //     itemHeight: 15,
        //     data: [
        //       {
        //          name:'股息率',
        //           icon:'circle',
        //         //  color:'rgbargba(86, 84, 202, 1)',
        //       },
        //     ],
        //        textStyle: {
        //       color: "#fff",
        //       fontSize: 14,
        //     },
        //     left: 20,
        //     y: 20,

        //      },

        //      {
        //     show: true,
        //     itemWidth: 30,
        //     itemHeight: 5,
        //     data: [
        //     {
        //       name:'指数点位',
        //       icon:'roundRect',
        //       color:'rgba(56, 56, 118, 1)'
        //     }
        //     ],
        //      textStyle: {
        //       color: "#fff",
        //       fontSize: 14,
        //     },
        //       left: 100,
        //       y: 20,

        //    },

        //    {
        //     show: true,
        //     itemWidth: 15,
        //     itemHeight: 15,
        //     data: [
        //     {
        //       name:'IPO标志',
        //       icon:'triangle',
        //     }
        //     ],
        //      textStyle: {
        //       color: "#fff",
        //       fontSize: 14,
        //     },
        //     left: 220,
        //       y: 20,

        //    },

        //     {
        //     show: true,
        //     itemWidth: 30,
        //     itemHeight: 5,
        //     data: [
        //       {
        //          name:'标准差（+1）',
        //         lineStyle:{
        //           type:'dotted',
        //           width: 3,
        //         }
        //         //  color:'rgbargba(86, 84, 202, 1)',
        //       },
        //     ],
        //        textStyle: {
        //       color: "#fff",
        //       fontSize: 14,
        //     },
        //     left: 350,
        //     y: 20,

        //       },

        //     {
        //     show: true,
        //     itemWidth: 30,
        //     itemHeight: 5,
        //     data: [
        //       {
        //          name:'平均值',
        //          icon:'roundRect',
        //         //  color:'rgbargba(86, 84, 202, 1)',
        //       },
        //     ],
        //        textStyle: {
        //       color: "#fff",
        //       fontSize: 14,
        //     },
        //     left: 490,
        //     y: 20,

        //       },

        //          {
        //     show: true,
        //     itemWidth: 30,
        //     itemHeight: 5,
        //     data: [
        //       {
        //          name:'标准差（-1）',
        //         lineStyle:{
        //           type:'dotted',
        //           width: 3,
        //         }
        //         //  color:'rgbargba(86, 84, 202, 1)',
        //       },
        //     ],
        //        textStyle: {
        //       color: "#fff",
        //       fontSize: 14,
        //     },
        //     left: 590,
        //     y: 20,

        //       },

        // ],

        xAxis: {
          type: 'category',
          boundaryGap: false,
          splitNumber: 4,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            margin: 20,
            fontSize: 14,
            show: true,
            showMaxLabel: true,
            color: '#d5d5d6',
          },

          data: X,
          // data: ['1','2','3','4'],
        },
        yAxis: {
          scale: true,
          // type: 'value',
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          position: 'left',
          axisLabel: {
            fontSize: 14,
            show: true,
            color: '#d5d5d6',
            //  formatter: function (args) {
            //     if( String(args).length == 3){
            //           return args +'0'+'%'
            //         }else{
            //           return args+'%'
            //         }
            //   },
          },
          splitLine: {
            show: false,
          },
        },

        series: [
          {
            // data: [29000, 33000, 34000, 34100],
            name: Y[0].name,
            data: Y[0].data,
            yAxisIndex: 0,
            type: 'line',
            areaStyle: {
              color: 'rgba(93, 204, 200, 1)',
            },

            itemStyle: {
              color: 'rgba(93, 204, 200, 1)',
            },
            symbol: 'none',
          },

          {
            name: Y[1].name,
            data: Y[1].data,
            yAxisIndex: 1,
            type: 'line',

            itemStyle: {
              color: 'rgba(86, 84, 202, 1)',
            },
            symbol: 'none',
          },

          // {
          //   name: Y[1].name,
          //   data: Y[1].data,
          //   type: "line",
          //   itemStyle: {
          //     color: "rgba(212, 48, 48, 1)",
          //   },

          //   symbol: "none",
          //   markPoint: {
          //     // 标记的数据，可以是最大值最小值也可以是自定义的坐标
          //     data: markPointData,
          //     symbol: "triangle", // 标记图形
          //     symbolSize: 18,
          //     symbolOffset: [0, "50%"],

          //     // 标注点的样式
          //     itemStyle: {
          //       color: "rgba(212, 48, 48, 1)", // 标注点颜色
          //       label: {
          //         show: true,
          //       },
          //     },
          //   },
          // },
        ],
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      // this.funResize()
    },

    // 4. 获取 相关机构
    async fnGetOperationInfo() {
      let code = this.CODE;

      const { data } = await this.$https.get('/api/operation_info?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);

      let obj = data.data[0];
      let data1 = Object.entries(obj);
      this.dOperationInfo = data1;
    },

    fnGetCbtns(data) {
      if (data == '概览') {
        this.bButtons1_card1 = true;
        this.bButtons1_card2 = false;
        this.bButtons1_card3 = false;
        this.bButtons1_card4 = false;
        this.dButtons1_current = '概览';

        (this.CODE = window.sessionStorage.getItem('CURRENTCODE')),
          // (this.STOCKPRICE = null),
          (this.STOCKNAME = window.sessionStorage.getItem('CURRENTNAME')),
          this.fnGetStockMinsData();
        this.fnGetStockSnapTable();
        this.fnGetAssetValueByCode();
        this.fnGetStockCandle();
        this.fnGetStockMvCharts();
      }

      if (data == '底层资产') {
        this.bButtons1_card1 = false;
        this.bButtons1_card2 = true;
        this.bButtons1_card3 = false;
        this.bButtons1_card4 = false;
        this.dButtons1_current = '底层资产';
        this.fnGetSingleAssetArea();
      }

      if (data == '交易结构') {
        this.bButtons1_card1 = false;
        this.bButtons1_card2 = false;
        this.bButtons1_card3 = true;
        this.bButtons1_card4 = false;
        this.dButtons1_current = '交易结构';
        this.fnGetDealStructure();
      }

      if (data == '相关机构') {
        this.bButtons1_card1 = false;
        this.bButtons1_card2 = false;
        this.bButtons1_card3 = false;
        this.bButtons1_card4 = true;
        this.dButtons1_current = '相关机构';
        this.fnGetOperationInfo();
      }
    },

    // 副本
    //  2. 底层资产估值变化（亿元）
    async fnGetSingleAssetArea() {
      // let code = "508000.SH";
      let code = this.CODE;

      const { data } = await this.$https.get('/api/single_asset_area?code=' + code);

      if (data.code !== 200) return this.$message.error(data.msg);

      // this.dSingleAssetArea = data.data;
      let DATA = data.data;

      this.chart3_data = DATA;
      this.$nextTick(() => {
        this.initChart3_1();
      });
    },

    initChart3_1() {
      this.chartColumn3 = this.$echarts.init(this.$refs.chartColumn3);
      this.chartColumn3.clear();

      let DATA = this.chart3_data;

      // 值除以10000
      let data = DATA;
      console.log(data.area_plot, ' data.area_plot');
      let lineData = data.area_plot;

      let X = lineData.map((node) => {
        return node.rp_date;
      });

      let keys = Object.keys(lineData[0]);
      let str1 = keys[1];
      let str2 = keys[2];
      keys.shift();
      let seriesArr = [];
      keys.forEach((node) => {
        let obj = {
          name: node,
          data: [],
        };
        data.area_plot.forEach((item) => {
          obj.data.push(item[node]);
        });
        seriesArr.push(obj);
      });

      // ----------------废弃的--------------
      // let Y1 = {
      //   name: str1,
      //   data: [],
      // };

      // lineData.forEach((node) => {
      //   Y1.data.push(node[str1]);
      // });

      // let Y2 = {
      //   name: str2,

      //   data: [],
      // };

      // lineData.forEach((node) => {
      //   Y2.data.push(node[str2]);
      // });

      // console.log(Y1,'Y1');
      // console.log(Y2,'Y2');

      // ----------------废弃的--------------

      let OPTIONDATA =
        this.$store.state.theme == 'dark'
          ? this.$store.state.OPTIONDATA
          : this.$store.state.OPTIONDATA_light;

      let ipo_date = data.ipo_date;

      let markPointData = ipo_date.map((node) => {
        let obj = {
          yAxis: 0,
          xAxis: node.IPO_date,
          //  字体会重叠
          label: {
            normal: {
              show: true,
              // formatter: `{d|${yIDataPointValue}} {b|bps}`,
              formatter: `${node.type_}`,
              margin: 10,
              position: ['120%', '30%'],
              textStyle: {
                color: OPTIONDATA.title.textStyle.color,
              },
            },
          },
          // blur:{
          //   label:{
          //     show:false,
          //     position:'bottom'
          //   }
          // }
        };
        return obj;
      });

      let series = seriesArr.map((node, idx) => {
        return {
          type: 'line',
          name: node.name,
          data: node.data,
          symbol: 'none',
          stack: 'all',
          // itemStyle: {
          //   color: function () {
          //     return "pink";
          //   },
          // },
          areaStyle: {
            // color: "rgba(90, 202, 198, 1)",
          },
        };
      });

      let obj = {
        name: 'IPO标志',
        type: 'line',
        symbol: 'none',
        markPoint: {
          // 标记的数据，可以是最大值最小值也可以是自定义的坐标
          data: markPointData,
          symbol: 'triangle', // 标记图形
          symbolSize: 18,
          symbolOffset: [0, '50%'],
          // 标注点的样式
          itemStyle: {
            color: 'rgba(212, 48, 48, 1)', // 标注点颜色
            label: {
              show: true,
            },
          },
        },
      };

      series.push(obj);

      let option = {
        color: [
          '#5acac6',
          '#598fc8',
          '#00A6FF',
          '#85D907',
          '#BEE5EB',
          '#1B75CF',
          '#1B75CF',
          '#87CEFA',
          '#C8FF00',
          '#FFFF44',
          '#C3E874',
          '#ff461e',
        ],
        title: {
          // 标题设置
          text: '底层资产估值变化', // 标题文字
          textStyle: {
            // 标题文字样式设置
            color: OPTIONDATA.title.textStyle.color,

            fontSize: 16,
          },
          left: 'center',
          top: this.ROOTFONTFIZE * (20 / 144),
        },
        grid: {
          top: keys.length > 8 ? this.ROOTFONTFIZE * (145 / 144) : this.ROOTFONTFIZE * (115 / 144),
          bottom: this.ROOTFONTFIZE * (0 / 144),
          left: this.ROOTFONTFIZE * (30 / 144),
          right: this.ROOTFONTFIZE * (50 / 144),
          containLabel: true,
        },
        legend: {
          show: true,
          orient: 'horizontal',
          // left:'right',
          left: this.ROOTFONTFIZE * (20 / 144),
          align: 'left',
          top: this.ROOTFONTFIZE * (50 / 144),
          // x:50,
          // x2: 150,
          icon: 'roundRect',
          itemGap: this.ROOTFONTFIZE * (15 / 144),
          itemWidth: this.ROOTFONTFIZE * (10 / 144),
          itemHeight: this.ROOTFONTFIZE * (14 / 144),
          padding: [0, 0, 0, 0],
          lineStyle: {
            color: 'inherit',
          },
          textStyle: {
            color: OPTIONDATA.legend.textStyle.color,
            fontSize: 14,
          },
          data: keys,
        },
        tooltip: {
          // 工具提示
          // trigger:'item',
          trigger: 'axis', // 工具提示的类型 item代表的是每个柱本身, axis代表的是坐标轴
          triggerOn: 'mousemove|click', // 触发时机, click代表点击, mouseOver代表鼠标移过
          formatter: function (args) {
            let str = '';
            str += `${args[0].axisValueLabel}`;
            args.forEach((node) => {
              let val = node.value / 10000;
              let num = (parseInt(Math.abs(val)) + '').length >= 2 ? 1 : 2;
              val = Number(val).toFixed(num);
              str += '<br>';
              str += `${node.marker}${node.seriesName}：${Number(val) !== 0 ? val : 0}${
                Number(val) !== 0 ? '' : ''
              }`;

              return str;
            });
            return str;
          },
        },
        xAxis: {
          type: 'category',
          // data: ['S＆P500', '10Yr国债', '沪深300', '公用指数', 'CREITs','','','',''],
          data: X,

          axisLine: {
            show: true,
            onZero: false, //轴线是否在0刻度轴上
          },

          axisTick: {
            show: true,
          },
          axisLabel: {
            // interval: 0,
            fontSize: this.ROOTFONTFIZE * (14 / 144),
            // rotate: 5,
            show: true,
            showMaxLabel: true,
            margin: this.ROOTFONTFIZE * (30 / 144),
            color: OPTIONDATA.xAxis.axisLabel.color,
          },
        },
        yAxis: {
          name: '单位：(亿元)',
          nameTextStyle: {
            color: OPTIONDATA.yAxis.nameTextStyle.color,
            fontSize: OPTIONDATA.yAxis.nameTextStyle.fontSize,
          },
          nameGap: OPTIONDATA.yAxis.nameGap,
          type: 'value',
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
            //x轴刻度相关设置
            alignWithLabel: true,
          },
          axisLabel: {
            fontSize: this.ROOTFONTFIZE * (OPTIONDATA.yAxis.axisLabel.fontSize / 144),
            show: true,
            color: OPTIONDATA.yAxis.axisLabel.color,
            formatter: function (args) {
              args = Number(args) / 10000;
              let num = (parseInt(Math.abs(args)) + '').length >= 2 ? 1 : 2;
              args = args.toFixed(num);
              return args;
            },
          },
          // data: [0, 1, 2, 3, 4],
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: OPTIONDATA.splitLine.lineStyle.color,
            },
          },
        },
        series: series,
        // series: [
        //   {
        //     // type: "line",
        //     // name: Y1.name,
        //     // data: Y1.data,
        //     // symbol: "none",
        //     // stack: "all",
        //     // itemStyle: {
        //     //   color: "rgba(90, 202, 198, 1)",
        //     // },
        //     // areaStyle: {
        //     //   color: "rgba(90, 202, 198, 1)",
        //     // },
        //   },
        //   {
        //     type: "line",
        //     stack: "all",
        //     symbol: "none",
        //     name: Y2.name,
        //     data: Y2.data,
        //     itemStyle: {
        //       color: "rgba(89, 143, 200, 1)",
        //     },
        //     areaStyle: {
        //       color: "rgba(89, 143, 200, 1)",
        //     },
        //   },
        // {

        // },
        // ],
      };

      this.chartColumn3.setOption(option);
      this.chartColumn3.resize();
      // this.funResize()
    },

    fnChangeBtn1() {},

    // 获取交易结构
    async fnGetDealStructure() {
      let reitCode = this.CODE;

      let style = this.$store.state.theme == 'dark' ? 'black' : 'white';

      const { data } = await this.$https.get(
        'api/conghua/dealStructure/list?reitCode=' + reitCode + '&style=' + style
      );
      let pre = '';
      this.structureImgs = data.map((node) => {
        let obj = {
          src: pre + node['filePath'],
          name: node['fileName'],
        };
        return obj;
      });

      this.structureImgs2 = this.structureImgs.map((node) => {
        return node.src;
      });
    },
  },
  components: {
    CSelects,
    CCategoryRadio,
    CButtons,
    stockIpoHistTable,
  },
};
</script>

<style lang="less" scoped>
.container {
}

.CARD {
  border-radius: calc(var(--ROOTFONTFIZE) * (20 / 144));
  background-color: var(--charts_bgc);
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) 0;
}

.CARD_tit {
  padding-left: calc(var(--ROOTFONTFIZE) * (22 / 144));
  // border-left: 5px solid rgba(21, 115, 254, 1);
  border-left: calc(var(--ROOTFONTFIZE) * (5 / 144)) solid var(--primary-color);
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  font-weight: 700;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (30 / 144));
  // height: 20px;
  color: var(--chart_title);
}

.CARD_tit2 {
  font-size: calc(var(--ROOTFONTFIZE) * (22 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (28 / 144));
  color: var(--chart_title);
  margin-top: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.CARD_body {
  padding: 0 calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.CARD_body_tabs {
  margin-top: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.marketValue {
  margin-top: calc(var(--ROOTFONTFIZE) * (35 / 144));
}

.marketValue .value span:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (36 / 144));
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: var(--chart_title);
}

.marketValue .value span:nth-child(2) {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: rgba(49, 208, 104, 1);
  margin-left: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.marketValue .time {
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: calc(var(--ROOTFONTFIZE) * (16 / 144));
  color: var(--chart_title);
  margin-top: calc(var(--ROOTFONTFIZE) * (18 / 144));
}

.CARD_body_echart {
  height: calc(var(--ROOTFONTFIZE) * (245 / 144));
  // background: rgba(0, 0, 0, 0.1);
  background-color: var(--charts_bgc);
  // border: 0.2px solid rgba(135, 135, 135, 1);
  border: 1px solid var(--charts_brc);
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144));
  margin: calc(var(--ROOTFONTFIZE) * (40 / 144)) calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.CARD_body_table {
  height: calc(var(--ROOTFONTFIZE) * (245 / 144));
  border-radius: calc(var(--ROOTFONTFIZE) * (5 / 144));
  margin: calc(var(--ROOTFONTFIZE) * (40 / 144)) calc(var(--ROOTFONTFIZE) * (30 / 144));
}

// table start
.TABLES {
  height: auto;
  display: flex;
  justify-content: space-between;
  // background-color: var(--overview_table_bgc);
  background-color: var(--overview_table_bgc2);
}

.TABLES_borderbottom_none {
  border-bottom: none;
}

table {
  border: none;
  flex: 1;
  margin-right: calc(var(--ROOTFONTFIZE) * (25 / 144));
}

table:nth-child(3) {
  margin-right: 0px;
}

.rowArea {
  display: flex;
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) 0;
  border-bottom: 1px solid #97949b;
  // background-color: #fff;
  background-color: var(--overview_table_bgc);
}

.rowArea:last-child {
  border-bottom: none;
}

.rowArea td {
}

.rowArea td:nth-child(1) {
  font-size: calc(var(--ROOTFONTFIZE) * (18 / 144));
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (24 / 144));
  color: var(--table_color);
  flex: 4;
  padding-left: calc(var(--ROOTFONTFIZE) * (13 / 144));
  border-right: calc(var(--ROOTFONTFIZE) * (2 / 144)) solid var(--rowArea_val_color);
}

.rowArea td:nth-child(2) {
  flex: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  // color: rgba(254, 254, 254, 1);
  color: var(--rowArea_val_color);
}

.even {
  background-color: var(--even_bgc) !important;
}

.rowArea_val_color {
  color: var(--rowArea_val_color);
}

.oddColor {
  background-color: var(--not_even_bgc);
}

// .secondTable start
.middle_table_area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle_table_area_left,
.middle_table_area_right {
  width: 100%;
  flex-flow: 1;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}

.middle_table_area_center {
  width: auto;
  display: flex;
  align-items: center;
}

// .secondTable td:nth-child(2) {
//   display: flex;
//   justify-content: space-between;
// }

.middle_table_area_center_line {
  display: inline-block;
  width: calc(var(--ROOTFONTFIZE) * (93 / 144));
  height: calc(var(--ROOTFONTFIZE) * (3 / 144));
  // background-color: var(--line_bgc);
  background-color: rgba(90, 202, 198, 1);
  // margin: 0 calc(var(--R  OOTFONTFIZE) * (15 / 144));
  position: relative;
}

// .secondTable end

// thirdTable start
.thirdTable td:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.thirdTable td:nth-child(2) div {
  flex: 1;
  box-sizing: border-box;
}

.thirdTable td:nth-child(2) div:nth-child(1) {
  text-align: right;
  padding-right: calc(var(--ROOTFONTFIZE) * (15 / 144));
}

.thirdTable td:nth-child(2) div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// .thirdTable td:nth-child(2) span:nth-child(1) {
.fLINESTYLE2 {
  display: inline-block;
  width: calc(var(--ROOTFONTFIZE) * (93 / 144));
  height: calc(var(--ROOTFONTFIZE) * (3 / 144));
  // background-color: var(--line_bgc);
  background-color: rgba(90, 202, 198, 1);
  position: relative;
}

.LINEVALUE {
  text-align: left;
  padding-left: calc(var(--ROOTFONTFIZE) * (15 / 144));
}

.pointStyle {
  width: calc(var(--ROOTFONTFIZE) * (10 / 144));
  height: calc(var(--ROOTFONTFIZE) * (10 / 144));
  border-radius: 50%;
  background-color: var(--line_point_bgc);
}

.NDJGFW_position {
  position: absolute;
  top: 50%;
  left: var(--NDJGFW);
  transform: translate(-50%, -50%);
}

.NDCJEFW_position {
  position: absolute;
  top: 50%;
  left: var(--NDCJEFW);
  transform: translate(-50%, -50%);
}

.HSL_position {
  position: absolute;
  top: 50%;
  left: var(--HSL);
  transform: translate(-50%, -50%);
}

.SZFW_position {
  position: absolute;
  top: 50%;
  left: var(--SZFW);
  transform: translate(-50%, -50%);
}

.PNAV_position {
  position: absolute;
  top: 50%;
  left: var(--PNAV);
  transform: translate(-50%, -50%);
}

.PFFO_position {
  position: absolute;
  top: 50%;
  left: var(--PFFO);
  transform: translate(-50%, -50%);
}

.PCFO_position {
  position: absolute;
  top: 50%;
  left: var(--PCFO);
  transform: translate(-50%, -50%);
}

// table end

// 相关数据
.RelatedInstitutions {
  background-color: var(--charts_bgc);
  padding: 0 calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.RelatedInstitutions_item {
  display: flex;
  align-items: center;
  padding: calc(var(--ROOTFONTFIZE) * (15 / 144)) 0;
  padding-left: calc(var(--ROOTFONTFIZE) * (50 / 144));
}

.RelatedInstitutions_item:last-child {
  // border-bottom: 1px solid #d6d6d6;
  border-bottom: 1px solid #97949b;
}

.RelatedInstitutions_item_left {
  flex: 1;
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  font-weight: 600;
  letter-spacing: 0px;
  line-height: calc(var(--ROOTFONTFIZE) * (23.17 / 144));
  color: var(--RelatedInstitutions_item_color1);
}

.RelatedInstitutions_item_right {
  flex: 9;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  font-weight: 400;
  letter-spacing: 0.1px;
  line-height: calc(var(--ROOTFONTFIZE) * (20 / 144));
  color: var(--RelatedInstitutions_item_color2);
}

.odd {
  // background-color: #2f2f36;
  // background-color: #343742;
  background-color: var(--even_bgc);
  // border-top: 1px solid #4d4040;
  // border-top: 1px solid #4c4d5f;
  // border-bottom: 1px solid #4c4d5d;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d6d6d6;

  border-top: 1px solid #97949b;
  border-bottom: 1px solid #97949b;
}

// k线图
.chart_area {
  display: flex;
  margin-top: calc(var(--ROOTFONTFIZE) * (20 / 144));
}

.chart_area_left,
.chart_area_right {
  flex: 1;
  background-color: #1a1c21;
  border: 1px solid #3b3c40;
  background-color: var(--charts_bgc);
  border: 1px solid var(--charts_brc);

  border-radius: calc(var(--ROOTFONTFIZE) * (10 / 144));
  height: calc(var(--ROOTFONTFIZE) * (520 / 144));
  position: relative;
}

.chart_area_left {
  margin-right: calc(var(--ROOTFONTFIZE) * (30 / 144));
}

.chart_area_right {
}

.cutBtn {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.HEIGHT_500 {
  height: calc(var(--ROOTFONTFIZE) * (500 / 144)) !important;
}

.medium {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.medium .name {
  color: var(--rowArea_val_color);
  font-size: calc(var(--ROOTFONTFIZE) * (16 / 144));
  line-height: calc(var(--ROOTFONTFIZE) * (50 / 144));
  margin-top: calc(var(--ROOTFONTFIZE) * (10 / 144));
  // background-color: pink;
}

::v-deep .carousel_item {
  background-color: transparent !important;
}

::v-deep .el-table {
  border: none !important;
  background-color: var(--table_bgc);
}

::v-deep .el-table::before {
  background-color: var(--table_bgc);
}

::v-deep .table1 table {
  border: none;
}

::v-deep .el-table__body .el-table__row .el-table__cell {
  background-color: var(--table_bgc);
  color: var(--table_color);
  border-bottom: 1px solid var(--table_brc);
  height: calc(var(--ROOTFONTFIZE) * (60 / 144));
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: var(--table_bgc_hover);
  color: var(--table_color_hover);
}

// 轮播图 start
.el-carousel__item h3 {
  color: #475669;
  font-size: calc(var(--ROOTFONTFIZE) * (14 / 144));
  opacity: 0.75;
  line-height: calc(var(--ROOTFONTFIZE) * (200 / 144));
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

// 轮播图 end
</style>
